import { createSlice } from '@reduxjs/toolkit';
import { EVENT_TYPE_IDS } from '../../constants/ids';
import { EVENT_KEYS } from '../../constants/names';
import { filteredEventsExtraReducers } from '../thunks/filteredEventsThunk';

const {
  AREA, DATE_FROM, DATE_TILL, REGION, COUNTRY, CITY, EVENT_TYPE, CASE_LIST, LANGUAGES, LOCATIONS,
} = EVENT_KEYS;

const resetedState = {
  [AREA]: [],
  [DATE_FROM]: new Date(),
  [DATE_TILL]: new Date(),
  [REGION]: [],
  [COUNTRY]: [],
  [CITY]: [],
  [EVENT_TYPE]: Object.values(EVENT_TYPE_IDS),
  [CASE_LIST]: [],
  [LANGUAGES]: [],
  [LOCATIONS]: [],
  events: [],
  doctorCounts: null,
  checkedEvents: [],
  isDateActive: false,
};

const initialState = {
  isOpen: false,
  filters: resetedState,
  prevFilters: resetedState,
  allItems: {
    [AREA]: [],
    [REGION]: [],
    [COUNTRY]: [],
    [CITY]: [],
    [CASE_LIST]: [],
    [LANGUAGES]: [],
    [LOCATIONS]: [],
  },
  eventsData: {
    events: [],
    eventsIds: [],
    doctors: null,
    isEventsLoading: false,
  },
  formatedLocations: {
    allRegions: {
      items: [],
      count: 0,
      checkCount: 0,
    },
    allCountries: {
      items: [],
      count: 0,
      checkCount: 0,
      visibleCount: 0,
    },
    allCities: {
      items: [],
      count: 0,
      checkCount: 0,
      visibleCount: 0,
    },
  },
  optionsLoading: false,
  isAreasLoading: true,
  isPatientsLoading: true,
  isLanguagesLoading: true,
  isLocationsLoading: true,
  error: null,
};

export const filteredEventsSlice = createSlice({
  name: 'filteredEvents',
  initialState,
  reducers: {
    updateFilters: (state, { payload }) => {
      const {
        name, value, isAll, isRadio,
      } = payload;
      const currentFilter = state.filters[name];
      if ([ AREA, REGION, COUNTRY, CITY, CASE_LIST, LANGUAGES ].includes(name)) {
        if (isAll) {
          const selectedItems = state.filters[name];
          let currentAllItems;
          if (name === CASE_LIST) {
            currentAllItems = state.allItems[name].map(item => item.id);
          } else {
            currentAllItems = state.allItems[name];
          }
          state.filters[name] = selectedItems.length === currentAllItems.length
            ? []
            : currentAllItems;
        } else if (currentFilter.includes(value)) {
          state.filters[name] = isRadio ? [] : currentFilter.filter(item => item !== value);
        } else {
          isRadio
            ? state.filters[name] = [ value ]
            : currentFilter.push(value);
        }
      } else {
        state.filters[name] = value;
      }
    },
    toggleRegion: (state, { payload }) => {
      const {
        unchecked, index, all, isAllChecked,
      } = payload;
      if (all) {
        state.allItems[LOCATIONS].forEach((item) => {
          item.unchecked = isAllChecked;
        });
      } else {
        state.allItems[LOCATIONS][index].unchecked = !unchecked;
      }
    },
    toggleCountry: (state, { payload }) => {
      const {
        unchecked, index, regionIndex, all, isAllChecked,
      } = payload;
      if (all) {
        state.allItems[LOCATIONS].forEach((region) => {
          region.country.forEach((country) => {
            country.unchecked = isAllChecked;
          });
        });
      } else {
        state.allItems[LOCATIONS][regionIndex].country[index].unchecked = !unchecked;
      }
    },
    toggleCity: (state, { payload }) => {
      const {
        unchecked, index, regionIndex, countryIndex, all, isAllChecked,
      } = payload;
      if (all) {
        state.allItems[LOCATIONS].forEach((region) => {
          region.country.forEach((country) => {
            country.city.forEach((city) => {
              city.unchecked = isAllChecked;
            });
          });
        });
      } else {
        state.allItems[LOCATIONS][regionIndex].country[countryIndex].city[index].unchecked = !unchecked;
      }
    },
    toggleDateActivity: (state, { payload }) => {
      state.filters.isDateActive = payload;
    },
    toggleFilterEventTypes: (state, { payload }) => {
      if (state.filters[EVENT_TYPE].includes(payload)) {
        state.filters[EVENT_TYPE] = state.filters[EVENT_TYPE].filter(item => item !== payload);
      } else {
        state.filters[EVENT_TYPE].push(payload);
      }
    },
    resetFilteredEvents: () => initialState,
    resetFilters: (state) => {
      state.filters = resetedState;
    },
    resetFilterLocation: (state) => {
      state.allItems[LOCATIONS] = [];
    },
    setPrevFilters: (state) => {
      state.filters = state.prevFilters;
      state.allItems[LOCATIONS] = state.prevFilters[LOCATIONS];
    },
    changePrevFilters: (state) => {
      state.prevFilters = state.filters;
      state.prevFilters[LOCATIONS] = state.allItems[LOCATIONS];
    },
    toggleFilterModal: (state, { payload }) => {
      state.isOpen = payload;
    },
    setFormatedLocations: (state, { payload }) => {
      state.formatedLocations = payload;
    },
    setCheckedEvents: (state, { payload }) => {
      if (payload === 'all') {
        if (state.filters.checkedEvents.length === state.filters.events.length) {
          state.filters.checkedEvents = [];
        } else {
          state.filters.checkedEvents = state.filters.events.map(item => item.id);
        }
      } else if (state.filters.checkedEvents.includes(payload)) {
        state.filters.checkedEvents = state.filters.checkedEvents.filter(item => item !== payload);
      } else {
        state.filters.checkedEvents.push(payload);
      }
    },
  },
  extraReducers: filteredEventsExtraReducers,
});

// ACTIONS
export const {
  resetFilteredEvents, updateFilters, deleteEvent, setCheckedEvents, toggleRegion, toggleCountry,
  toggleCity, resetFilters, setPrevFilters, toggleFilterModal, changePrevFilters, resetFilterLocation,
  toggleDateActivity, setFormatedLocations, toggleFilterEventTypes,
} = filteredEventsSlice.actions;

// SELECTORS
export const selectFilters = state => state.filteredEvents.filters;

export default filteredEventsSlice.reducer;
