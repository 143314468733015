import { createSlice } from '@reduxjs/toolkit';
import { eventsDataExtraReducers } from '../thunks/eventsDataThunk';

const initialState = {
  events: [],
  isLoading: true,
  error: null,
};

export const eventsDataSlice = createSlice({
  name: 'eventsData',
  initialState,
  reducers: {
    resetEvents: () => initialState,
    updateEvent: (state, { payload }) => {
      const eventId = state.events.findIndex(item => item.id === payload.id);
      if (eventId !== -1) {
        state.events[eventId] = { ...state.events[eventId], ...payload };
      }
    },
    deleteEvent: (state, { payload }) => {
      state.events = state.events.filter(event => event.id !== payload);
    },
  },
  extraReducers: eventsDataExtraReducers,
});

// ACTIONS
export const { resetEvents, updateEvent, deleteEvent } = eventsDataSlice.actions;

// SELECTORS
export const selectEvents = state => state.eventsData.events;

export default eventsDataSlice.reducer;
