import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import EventItem from './components/EventItem/EventItem';
import s from './Events.module.scss';
import EventCardLoader from './components/EventItem/EventCardLoader';
import NoItem from '../../components/NoItem/NoItem';
import { selectModerator, setModeratorActiveEvent } from '../../redux/slices/moderatorSlice';
import { getModeratorEventsThunk } from '../../redux/thunks/moderatorThunk';
import QRPopup from '../../components/QRPopup/QRPopup';

const ModeratorEvents = () => {
  const dispatch = useDispatch();
  const { events, activeEvent, isLoading } = useSelector(selectModerator);
  const [ activeEvents, setActiveEvents ] = useState([]);
  const [ QRData, setQRData ] = useState(null);

  useEffect(() => {
    dispatch(getModeratorEventsThunk());
  }, []);

  const onEventClick = (id) => {
    if (activeEvents.includes(id)) {
      return setActiveEvents(activeEvents.filter(item => item !== id));
    }
    return setActiveEvents(prev => [ ...prev, id ]);
  };

  const onEventCheck = (event) => {
    dispatch(setModeratorActiveEvent(event));
  };

  return (
    <div className={s.futureRoot}>
      <Heading />
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className="cards">
              {(isLoading && !events.length)
                ? Array(1).fill(null).map((_, i) => (
                  <EventCardLoader key={i} action closable />
                ))
                : events.map((event) => {
                  if (!event) return null;
                  const isChecked = activeEvent?.id === event.id;
                  return (
                    <EventItem
                      key={event.id}
                      moderator
                      check
                      checked={isChecked}
                      onCheck={() => !isChecked && onEventCheck(event)}
                      event={event}
                      isActive={activeEvents.includes(event.id)}
                      setQRData={setQRData}
                      onEventClick={onEventClick} />);
                })
              }
              {(!isLoading && !events.length) && <NoItem title='Event' />}
            </div>
          </section>
        </section>
      </section>
      {/* modal */}
      {QRData && <QRPopup onClose={() => setQRData(null)} data={QRData} />}
    </div>
  );
};

export default ModeratorEvents;
