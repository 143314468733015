import { createSlice } from '@reduxjs/toolkit';
import { dictionarySettingsExtraReducers } from '../thunks/dictionarySettingsThunk';
import { settingsExtraReducers } from '../thunks/settingsThunk';

const initialState = {
  isSettingsLoading: true,
  images: {},
  dictionaries: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettings: () => initialState,
    addDictionary: (state, { payload }) => {
      state.dictionaries.push({
        new: true,
        name: payload,
        sheetURL: '',
      });
    },
    toggleDictionarySheet: (state, { payload }) => {
      const { i, value } = payload;
      state.dictionaries[i].sheetURL = value;
    },
    deleteDictionary: (state, { payload }) => {
      state.dictionaries = state.dictionaries.filter(item => item.name !== payload);
    },
  },
  extraReducers: (builder) => {
    settingsExtraReducers(builder);
    dictionarySettingsExtraReducers(builder);
  },
});

// ACTIONS
export const {
  resetSettings, addDictionary, toggleDictionarySheet, deleteDictionary,
} = settingsSlice.actions;

// SELECTORS
export const selectImages = state => state.settings.images;
export const selectDictionaries = state => state.settings.dictionaries;

export default settingsSlice.reducer;
