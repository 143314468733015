import { useSelector } from 'react-redux';
import { selectRole } from '../../redux/slices/userInfoSlice';

function Can({
  roles = [], children,
}) {
  const role = useSelector(selectRole);
  if (roles.includes(role)) return <>{children}</>;

  return null;
}

export default Can;
