import classNames from 'classnames';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select } from '../../../../../components/UI';
import Row from '../../../../../components/UI/Row/Row';
import { PATIENT_BASE_INFO_KEYS } from '../../../../../configs/patients.configs';
import { ALERT_TYPES } from '../../../../../constants/names';
import { setAlert } from '../../../../../redux/slices/alertSlice';
import s from '../PatientCasesModal.module.scss';
import PatientAvatarSection from './PatientAvatarSection';

const {
  GENDER, AGE, FIRST_NAME, LAST_NAME, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const PatientInfoSection = ({
  isView, formData, therapeuticSelect, setTherapeuticSelect, therapeuticOptions, imgUrl,
  fileHandler, removeAvatar, languageOptions, languageSelect, onImportClick, onSheetChange,
  toggleLanguageSelect, onClearClick, setIsPatientModalActive, formHandler,
}) => {
  const dispatch = useDispatch();
  const isImportLoading = useSelector(state => state.patientsData.isImportLoading);
  const isClearDisable = !formData.activeTranslation.sheet;
  const isImageHave = formData.avatar || imgUrl;

  const isImportDisabled = !languageSelect || !therapeuticSelect || !formData.activeTranslation.sheet;

  const { baseInfo, sheet, language } = formData.activeTranslation;

  const onShareClick = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}case=${formData.uuid}&language=${language}/`);
    dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
  };

  const previousCaseBaseInfo = useMemo(() => {
    if (!formData.previous) return null;
    let currentInfo = formData.previous.translations[0];
    const lang = languageSelect?.value;
    if (lang) {
      const findedInfo = formData.previous.translations.find(item => item.language === lang);
      findedInfo && (currentInfo = findedInfo);
    }
    return currentInfo.baseInfo;
  }, [ formData.previous, languageSelect ]);

  return (
    <div>
      <div className={s.section}>
        <div className={s.row}>
          <div>Therapeutic Area{!isView && <span className={s.required}>*</span>}:</div>
          <div>
            {isView
              ? <div className={s.viewRow}>{therapeuticSelect.value}</div>
              : <Select
                  isSearchable
                  placeholder='Select Therapeutic Area...'
                  value={therapeuticSelect}
                  onChange={value => setTherapeuticSelect(value)}
                  options={therapeuticOptions} />}
          </div>
        </div>
        <div className={s.row}>
          <div>Language{!isView && <span className={s.required}>*</span>}:</div>
          <div>
            <Select
              isSearchable
              placeholder='Select Language...'
              value={languageSelect}
              onChange={toggleLanguageSelect}
              options={languageOptions} />
          </div>
        </div>
        {isView
          ? <div className={s.row}>
            <div>Link for this Patient Case:</div>
            <div><span
              className={s.share}
              onClick={onShareClick}>
              Share
            </span>
            </div>
          </div>
          : <div className={s.row}>
            <div>Input File<span className={s.required}>*</span>:</div>
            <div>
              <textarea
                className={s.textarea}
                name='sheet'
                onChange={onSheetChange}
                value={sheet} />
            </div>
          </div>}
        {!isView && <div className={s.sectionActions}>
          <Button
            disabled={isClearDisable}
            onClick={onClearClick}
            className={classNames(s.sectionActions_button, s.clearButton)}
            >
            Clear
          </Button>
          <Button
            disabled={isImportDisabled}
            loading={isImportLoading}
            onClick={onImportClick}>Import</Button>
        </div>}
        <div className={s.row}>
          <div>Link Previous Patient Case:</div>
          <div>
            <div>
              {
                formData.previous
                  ? <div className={s.patientRowWrapper}>
                    <Row className={s.patientRow}>
                      <div>
                        {`${previousCaseBaseInfo[GENDER]} ${previousCaseBaseInfo[FIRST_NAME]}
                      ${previousCaseBaseInfo[LAST_NAME] ? ` ${previousCaseBaseInfo[LAST_NAME]}` : ''},
                        ${previousCaseBaseInfo[AGE]} - ${previousCaseBaseInfo[VISIT]} Visit`}
                      </div>
                    </Row>
                    {!isView
                && <div
                  onClick={() => formHandler('previous', null)}
                  className={s.recycleIcon} />}
                  </div>
                  : <Button
                      onClick={() => setIsPatientModalActive(true)}
                      className={classNames(s.patientButton, s.grayButton)}>
                    Choose Patient Case
                  </Button>
                }
            </div>
          </div>
        </div>
        {formData.previous && !isView && <div className={s.row}>
          <div />
          <div>
            <Button
              onClick={() => setIsPatientModalActive(true)}
              className={classNames(s.patientButton, s.grayButton)}>
              Choose Patient Case
            </Button>
          </div>
        </div>
        }
      </div>
      <div className={classNames(s.section, s.lastSection)}>
        <PatientAvatarSection
          view={isView}
          avatar={imgUrl || `${process.env.REACT_APP_IMAGE_URL}${formData.avatar}`}
          isImageHave={isImageHave}
          baseInfo={baseInfo}
          removeAvatar={removeAvatar}
          fileHandler={fileHandler} />
      </div>
    </div>
  );
};

export default PatientInfoSection;
