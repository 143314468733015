import { Card } from '../../../../components/UI';
import s from './EventItem.module.scss';
import EventItemLoader from './EventItemLoader';

const EventCardLoader = ({
  closable, past, open, action, short, check,
}) => (
  <div className={s.cardItem}>
    <Card>
      <EventItemLoader
        short={short}
        closable={closable}
        past={past}
        open={open}
        action={action}
        check={check} />
    </Card>
  </div>
);

export default EventCardLoader;
