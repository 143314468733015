import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import NoItem from '../../components/NoItem/NoItem';
import SortButton from '../../components/SortButton/SortButton';
import { CustomSelect } from '../../components/UI';
import { EVENTS_SORT_OPTIONS } from '../../configs/events.configs';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { resetEvents } from '../../redux/slices/eventsSilce';
import { getEventsThunk } from '../../redux/thunks/eventsDataThunk';
import EventCardLoader from './components/EventItem/EventCardLoader';
import EventItem from './components/EventItem/EventItem';
import s from './Events.module.scss';

const PastEvents = () => {
  const dispatch = useDispatch();
  const { events, isLoading } = useSelector(state => state.eventsData);
  const [ activeEvents, setActiveEvents ] = useState([]);
  const [ isDesc, setIsDesc ] = useState(false);
  const [ sortValue, setSortValue ] = useState(null);
  const [ sortLabel, setSortLabel ] = useState('Default');

  const isSortDisabled = events.length < 2;

  const { lastTicketRef, scrollLoading, restartFetch } = useInfiniteScroll(
    page => dispatch(getEventsThunk({
      page, isDesc, isPast: true, sort: sortValue?.value,
    })),
    [ isDesc, sortValue ],
    () => dispatch(resetEvents()),
  );

  const changeSort = (value) => {
    setSortLabel(value.label);
    setSortValue(value);
  };

  const onEventClick = (id) => {
    if (activeEvents.includes(id)) {
      return setActiveEvents(activeEvents.filter(item => item !== id));
    }
    return setActiveEvents(prev => [ ...prev, id ]);
  };

  return (
    <div className={s.futureRoot}>
      <Heading>
        <div className={s.headingActions}>
          <div className={s.headingActionInfo}>
            <div className={s.headingActionInfoItem}>
              <label>Sort :&nbsp;</label>
              <span>{sortLabel}</span>
              <span>{isDesc ? '(Descending)' : '(Ascending)'}</span>
            </div>
          </div>
          <div className={s.headingActionItem}>
            <div className={s.sortDropDownButton}>
              <CustomSelect
                isDisabled={isSortDisabled}
                value={sortValue}
                onChange={value => changeSort(value)}
                options={EVENTS_SORT_OPTIONS} />
            </div>
            <SortButton
              disabled={isSortDisabled}
              desc={isDesc}
              onClick={() => !isSortDisabled && setIsDesc(prev => !prev)} />
          </div>
        </div>
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className="cards">
              {(isLoading && !events.length)
                ? Array(1).fill(null).map((_, idx) => (
                  <EventCardLoader key={idx} action closable past />
                ))
                : events.map((event, i) => {
                  if (!event) return null;
                  return (
                    <EventItem
                      past
                      key={event.id}
                      restartFetch={restartFetch}
                      lastTicketRef={i === events.length - 1 ? lastTicketRef : null}
                      event={event}
                      isActive={activeEvents.includes(event.id)}
                      onEventClick={onEventClick} />
                  );
                })
              }
              {!isLoading && !events.length && <NoItem title='Event' />}
              {scrollLoading && <EventCardLoader action closable past />}
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default PastEvents;
