import React from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import s from './VideoPopup.module.scss';

const VideoPopup = ({ video, onClose }) => (
  <div className={s.videoPopup}>
    <div
      className={s.videoPopupClose}
     />
    <div className={classNames(s.videoPopup__inner)}>
      <div className={s.videoPopup__pic}>
        <ReactPlayer
          url={video.videoUrl}
          playing
          className='react-player fixed-bottom'
          width='100%'
          height='100%'
          loop
          controls />
        <div
          className={s.videoPopup__close}
          onClick={e => onClose()}>
          <svg className={s.videoPopup__closeSvg}>
            <use xlinkHref="#close" />
          </svg>
        </div>
      </div>
    </div>
  </div>
);

export default VideoPopup;
