import ContentLoader from 'react-content-loader';

const EventItemLoader = ({
  closable, action, past, open, short, check, ...props
}) => {
  const actionPosition = open ? '4.3rem' : '1.2rem';
  const height = getHeight();

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={height}
      backgroundColor='rgb(170, 170, 170)'
      foregroundColor='rgb(230, 230, 230)'
      {...props}
        >
      {closable && <rect x="1.8rem" y="0" rx="0.375rem" ry="0.375rem" width="1.5rem" height="1.3rem" />}
      <rect x={open ? '3rem' : '4.75rem'} y="0" rx="0.375rem" ry="0.375rem" width="50%" height="1.3rem" />
      {!short && <rect x={open ? '3rem' : '4.75rem'} y="2.2rem" rx="0.375rem" ry="0.375rem" width="40%" height="1.3rem" />}
      {open && <>
        <rect x="3rem" y="4.4rem" rx="0.375rem" ry="0.375rem" width="35%" height="1.3rem" />
        <rect x="3rem" y="6.6rem" rx="0.375rem" ry="0.375rem" width="12rem" height="1.3rem" />
        <rect x="16rem" y="6.6rem" rx="0.375rem" ry="0.375rem" width="23.5%" height="1.3rem" />
        <rect x="16rem" y="8.5rem" rx="0.375rem" ry="0.375rem" width="23.5%" height="1.3rem" />

      </>}
      {action && <rect x="90%" y={actionPosition} rx="0.375rem" ry="0.375rem" width="5%" height="1.3rem" />}
      {check && <rect x="95%" y={0} rx="0.375rem" ry="0.375rem" width="1.3rem" height="1.3rem" />}
      {action && !past
    && <>
      <rect x="80%" y={actionPosition} rx="0.375rem" ry="0.375rem" width="2%" height="1.3rem" />
      <rect x="84%" y={actionPosition} rx="0.375rem" ry="0.375rem" width="4%" height="1.3rem" />
      <rect x="97%" y={actionPosition} rx="0.375rem" ry="0.375rem" width="1%" height="1.3rem" />
    </>}

    </ContentLoader>
  );

  function getHeight() {
    let currentHeight = '3.5rem';
    if (open) {
      currentHeight = '9.8rem';
    } else if (short) {
      currentHeight = '1.3rem';
    }
    return currentHeight;
  }
};
export default EventItemLoader;
