import React from 'react';
import ContentLoader from 'react-content-loader';

const RowLoader = ({
  color, height = '3rem', filter, ...props
}) => (
  <ContentLoader
    speed={1}
    width={'100%'}
    height={height}
    backgroundColor={color || 'var(--color-whitegray)'}
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x={filter ? '-50%' : '0'} y="0" rx="0.375rem" ry="0.375rem" width={filter ? '200%' : '100%'} height="100%" />
  </ContentLoader>
);

export default RowLoader;
