import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EVENT_KEYS } from '../../../../../../constants/names';
import { resetFilteredEvents } from '../../../../../../redux/slices/filteredEventsSlice';
import { getFilterAreasThunk, getFilterCasesThunk } from '../../../../../../redux/thunks/filteredEventsThunk';
import FilterItemList from '../../../FilterItemList/FilterItemList';

const { AREA } = EVENT_KEYS;

const FilterArea = ({ onItemClick }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuth = useSelector(state => state.userInfo.isAuth);
  const selectedAreas = useSelector(state => state.filteredEvents.filters[AREA]);
  const allAreas = useSelector(state => state.filteredEvents.allItems[AREA]);
  const isAreasLoading = useSelector(state => state.filteredEvents.isAreasLoading);
  const isPatientsLoading = useSelector(state => state.filteredEvents.isPatientsLoading);
  const isLanguagesLoading = useSelector(state => state.filteredEvents.isLanguagesLoading);
  const isLocationsLoading = useSelector(state => state.filteredEvents.isLocationsLoading);

  useEffect(() => {
    if ((pathname.includes('/events/') || pathname.includes('/patientCases/'))
    && (!isLocationsLoading && !isLocationsLoading && !isPatientsLoading && !isLanguagesLoading)) {
      dispatch(resetFilteredEvents());
      return () => {
        dispatch(getFilterAreasThunk());
      };
    }
  }, [ pathname ]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getFilterAreasThunk());
    } else {
      dispatch(resetFilteredEvents());
    }
  }, [ isAuth ]);

  useEffect(() => {
    dispatch(getFilterCasesThunk(selectedAreas));
  }, [ selectedAreas ]);

  return (
    <FilterItemList
      length={allAreas.length}
      loading={isAreasLoading}
      onSelectAll={() => onItemClick(AREA, null, true)}
      isAllChecked={selectedAreas.length === allAreas.length}
      title='Therapeutic Area'>
      {allAreas.map(item => (
        <FilterItemList.Item
          key={item}
          checked={selectedAreas.includes(item)}
          onClick={() => onItemClick(AREA, item)}
          value={item} />
      ))}
    </FilterItemList>
  );
};

export default FilterArea;
