import classNames from 'classnames';
import React from 'react';
import { PATIENT_BASE_INFO_KEYS } from '../../../../../configs/patients.configs';
import { GENDER_NAMES } from '../../../../../constants/names';
import s from '../PatientCasesModal.module.scss';

const {
  GENDER, AGE, FIRST_NAME, HISTORY, LAST_NAME, SYMPTOMS, VISIT, SYMPTOMS_KEY, HISTORY_KEY, VISIT_KEY,
} = PATIENT_BASE_INFO_KEYS;

const PatientAvatarSection = ({
  fileHandler, removeAvatar, isImageHave, baseInfo, avatar, view,
}) => (
  <div className={s.avatarSection}>
    <div className={classNames(s.imageActionField, { [s.hidden]: view })}>
      <div>Image:</div>
      <div className={s.dropzone}>
        <label className={classNames(s.chooseButton, s.grayButton)} htmlFor='chooseImage'>
          Choose
        </label>
        <input
          onChange={fileHandler}
          type='file'
          id='chooseImage'
          className={s.fileInput}
          accept=".jpg,.jpeg,.png,.jfif,.webp" />
      </div>
      <div className={s.maxPixels}><div>144 x 192</div><div>pixels max</div></div>
      <div
        onClick={removeAvatar}
        className={classNames(s.recycleIcon, { [s.disabled]: !isImageHave })} />
    </div>
    <div>
      <div className={s.patientCases__content}>
        <div className={s.patientCases__information}>
          <div className={s.patientCases__details}>
            <div className={classNames(s.imageWrapper, {
              [s.imageHave]: isImageHave,
            })} >
              {isImageHave && <img src={avatar} alt='patient' />}
            </div>
            <div className={s.patientCases__info}>
              {baseInfo && <div className={s.patientCases__person}>
                <div className={s.patientCases__name}>
                  {baseInfo[GENDER]} {baseInfo[FIRST_NAME] || ''} {baseInfo[LAST_NAME] || ''}
                </div>
                <div className={s.patientCases__gender}>
                  {GENDER_NAMES[baseInfo[GENDER]]}{baseInfo[AGE] && `, ${baseInfo[AGE]}`}
                </div>
                <div className={s.patientCases__description}>
                  {baseInfo[VISIT] && `${baseInfo[VISIT]} ${baseInfo[VISIT_KEY]}`}
                </div>
                </div>}
            </div>
          </div>
          {baseInfo && baseInfo[SYMPTOMS] && Array.isArray(baseInfo[SYMPTOMS]) && !!baseInfo[SYMPTOMS].length
  && <div className={s.patientCases__history}>
    <div className={s.patientCases__historyTitle}>{baseInfo[SYMPTOMS_KEY]}</div>
    <div className={s.patientCases__historyText}>
      {baseInfo[SYMPTOMS].map((paragraph, i) => (
        <p key={i} className={s.patientCases__historyText_paragraph}>
          {paragraph}
        </p>
      ))}
    </div>
  </div>
  }
          {baseInfo && baseInfo[HISTORY] && Array.isArray(baseInfo[HISTORY]) && !!baseInfo[HISTORY].length
  && <div className={s.patientCases__history}>
    <div className={s.patientCases__historyTitle}>{baseInfo[HISTORY_KEY]}</div>
    <div className={s.patientCases__historyText}>
      {baseInfo && baseInfo[HISTORY].map((paragraph, i) => (
        <p key={i} className={s.patientCases__historyText_paragraph}>
          {paragraph}
        </p>
      ))}
    </div>
  </div>
  }
        </div>
      </div>
    </div>
  </div>
);

export default PatientAvatarSection;
