import React from 'react';
import classNames from 'classnames';
import s from './PhotoPopup.module.scss';

const PhotoPopup = ({ img, smallPhoto, onClose }) => (
  <div className={s.photoPopup}>
    <div
      className={s.photoPopupClose}
     />
    <div className={classNames(s.photoPopup__inner, { [s.smallPhoto]: smallPhoto })}>
      <div className={s.photoPopup__pic}>
        <img src={img} alt="IMG" className={s.photoPopup__img} />
        <div
          className={s.photoPopup__close}
          onClick={e => onClose()}>
          <svg className={s.photoPopup__closeSvg}>
            <use xlinkHref="#close" />
          </svg>
        </div>
      </div>
    </div>
  </div>
);

export default PhotoPopup;
