import React from 'react';
import ReactSelect from 'react-select';

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '200px',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1.125rem',
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: '100%',
    opacity: state.isDisabled ? '0.7' : '1',
    boxShadow: 'none',
    backgroundColor: 'white',
    minHeight: '3.06rem',
    height: '3.06rem',
    fontSize: '1.125rem',
    fontWeight: '500',
    cursor: 'pointer',
    border: '1px solid #4FB1C2',
    borderRadius: '0.375rem',
    padding: '0 .5rem',
    whiteSpace: 'nowrap',
    color: 'var(--color-darkblue)',
    '&:hover': {
      borderColor: 'inherit',
    },
  }),
  indicatorSeparator: () => ({}),
  placeholder: provided => ({
    ...provided,
    color: 'var(--color-gray5)',
    opacity: 0.9,
  }),
  dropdownIndicator: (_, state) => ({
    padding: '2px 8px',
    // transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
    '& > svg': {
      display: 'block',
      width: '1.5rem !important',
      height: '1.5rem !important',
    },
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '2px 8px',
  }),
};

const Select = ({
  label, style = {}, width = '20rem', ...props
}) => (
  <ReactSelect
  // TODO fix indicator rotation
    hideSelectedOptions
    styles={customStyles}
    isSearchable={false}
    isClearable={false}
    width={width}
    {...props}
     />
);

export default Select;
