import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ViewRow } from '../../../../../../components/UI';
import Dropdown from './Dropdown';
import { USER_ROLES_IDS } from '../../../../../../constants/ids';
import { selectRole } from '../../../../../../redux/slices/userInfoSlice';
import s from './TimeZoneDropDown.module.scss';

const { SUPER_USER } = USER_ROLES_IDS;

const TimeZoneDropDown = ({ timeZone, toggleTimeZone, isView }) => {
  const timezoneInfo = useSelector(state => state.userInfo.timezones);
  const role = useSelector(selectRole);
  const [ isDropdownActive, setIsDropdownActive ] = useState(false);
  const getTimeZoneText = (t) => {
    const timezoneObj = timezoneInfo.find(i => i.id === t);
    return `${timezoneObj.location_name} - ${timezoneObj.name} (${timezoneObj.utc_offset})`;
  };
  const [ timeZoneText, setTimeZoneText ] = useState((timeZone !== '' && typeof timeZone !== 'undefined' && timeZone !== null)
    ? getTimeZoneText(timeZone) : '');

  const onTimeZoneClick = (el) => {
    if (el === timeZone) return;
    toggleTimeZone(el);
    setTimeZoneText(getTimeZoneText(el));
    setIsDropdownActive(false);
  };

  return (
    <div className={s.row}>
      <div>
        <div className={s.shortInput}>
          {isView
            ? <ViewRow>{timeZone}</ViewRow>
            : <Dropdown
                value={timeZoneText}
                active={isDropdownActive}
                isView={isView}
                toggle={value => role === SUPER_USER && setIsDropdownActive(value)}>
              <Dropdown.Modal className={s.timeZoneDropModal}>
                <div className={s.timeZoneList}>
                  {timezoneInfo.map(_ => (
                    <div
                      key={_.id}
                      onClick={() => onTimeZoneClick(_.id)}
                      className={classNames(s.timeZoneItem, { [s.activeItem]: timeZone === _.id })}>
                      <div className={s.infoBlock}>
                        <p>
                          <span className={s.bold}>{_.location_name} - {_.name}</span><br />{`(${_.utc_offset})`}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Dropdown.Modal>
            </Dropdown>
          }
        </div>
      </div>
    </div>
  );
};

export default TimeZoneDropDown;
