import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EVENT_KEYS } from '../../../../../../constants/names';
import { getFilterLocationsThunk } from '../../../../../../redux/thunks/filteredEventsThunk';
import FilterItemList from '../../../FilterItemList/FilterItemList';

const { LANGUAGES } = EVENT_KEYS;

const FilterLanguage = ({ onItemClick }) => {
  const dispatch = useDispatch();
  const selectedLanguages = useSelector(state => state.filteredEvents.filters[LANGUAGES]);
  const allLanguages = useSelector(state => state.filteredEvents.allItems[LANGUAGES]);
  const isLanguagesLoading = useSelector(state => state.filteredEvents.isLanguagesLoading);

  useEffect(() => {
    dispatch(getFilterLocationsThunk(selectedLanguages));
  }, [ selectedLanguages ]);

  return (
    <FilterItemList
      length={allLanguages.length}
      loading={isLanguagesLoading}
      onSelectAll={() => onItemClick(LANGUAGES, null, true)}
      isAllChecked={selectedLanguages.length === allLanguages.length}
      title='Languages'>
      {allLanguages.map(item => (
        <FilterItemList.Item
          key={item}
          checked={selectedLanguages.includes(item)}
          onClick={() => onItemClick(LANGUAGES, item)}
          value={item} />
      ))}
    </FilterItemList>
  );
};

export default FilterLanguage;
