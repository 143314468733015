import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET ACCESS USERS
export const getAccessUsersThunk = createAsyncThunk(
  'access/getAccessUsers',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/user/eventModerator/list').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getAccessUsersThunkPending = (state) => {
  state.isUsersLoading = true;
};

const getAccessUsersThunkFulfilled = (state, { payload }) => {
  state.isUsersLoading = false;
  if (payload) {
    state.users = payload;
    // eslint-disable-next-line prefer-destructuring
    state.activeUser = payload[0];
  }
};

// CREATE ACCESS
export const createAccessThunk = createAsyncThunk(
  'access/createAccess',
  async ({ isModerator, ...data }, { dispatch }) => {
    const response = await AXIOS.post('/user/eventModerator/create', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getAccessUsersThunk());
    return response.data;
  },
);

const createAccessThunkPending = (state) => {
  state.isCreateLoading = true;
};

const createAccessThunkFulfilled = (state) => {
  state.isCreateLoading = false;
};

const createAccessThunkRejected = (state) => {
  state.isCreateLoading = false;
};

// DELETE ACCESS USER
export const deleteAccessUserThunk = createAsyncThunk(
  'access/deleteAccessUser',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/user/eventModerator/delete', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getAccessUsersThunk());
    return response.data;
  },
);

const deleteAccessUserThunkFulfilled = (state) => {
  state.activeModerator = null;
};

// GET ACCESS EVENTS

export const getAccessEventsThunk = createAsyncThunk(
  'access/getAccessEvents',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/event/moderatorList', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getAccessEventsThunkPending = (state) => {
  state.isActiveEventsLoading = true;
};

const getAccessEventsThunkFulfilled = (state, { payload }) => {
  state.isActiveEventsLoading = false;
  state.activeEvents = payload;
};

// SELECT ACCESS USER EVENTS
export const selectAccessUserEventsThunk = createAsyncThunk(
  'access/selectAccessUsersEvents',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/user/eventModerator/permissions', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getAccessEventsThunk({ eventModeratorId: data.eventModeratorId }));
    return response.data;
  },
);

const selectAccessUserEventsThunkPending = (state) => {
  state.isSaveEventsLoading = true;
};

const selectAccessUserEventsThunkFulfilled = (state) => {
  state.isSaveEventsLoading = false;
};

const selectAccessUserEventsThunkRejected = (state) => {
  state.isSaveEventsLoading = false;
};

// UPDATE ACCESS USER
export const updateAccessUserThunk = createAsyncThunk(
  'access/updateAccessUser',
  async ({ id, username, password }, { dispatch }) => {
    const response = await AXIOS.post('/moderator/updateUsernameAndPassword', { id, username, password }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getAccessUsersThunk());
    return response.data;
  },
);

const updateAccessUserThunkPending = (state) => {
  state.isUpdateLoading = true;
};

const updateAccessUserThunkFulfilled = (state) => {
  state.isUpdateLoading = false;
};

const updateAccessUserThunkRejected = (state) => {
  state.isUpdateLoading = false;
};

// UPDATE MODERATOR
export const getOnlyLocationsThunk = createAsyncThunk(
  'access/getLocations',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/filter/getNotFilteredOtherInfo').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getOnlyLocationsThunkPending = (state) => {
  state.filterOptions.isLocationsLoading = true;
};

const getOnlyLocationsThunkFulfilled = (state, { payload }) => {
  state.filterOptions.isLocationsLoading = false;
  state.filterOptions.locations = payload;
};

// GET MODERATOR FILTERED EVENTS
export const getAccessFilteredEventsThunk = createAsyncThunk(
  'access/getAccessFilteredEvents',
  async (_, { dispatch, getState }) => {
    const finalData = formatFilterData(getState().access.filterOptions);
    const response = await AXIOS.post('/filter/getEventsByOtherInfo', finalData).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

function formatFilterData(data) {
  const {
    locations, eventTypes, isDateActive, startDate, endDate,
  } = data;
  const regions = [];
  const countries = [];
  const cities = [];
  let isAllCitiesChecked = true;

  locations.forEach((regionItem) => {
    if (!regionItem.unchecked) {
      regions.push(regionItem.region);
      regionItem.country.forEach((countryItem) => {
        if (!countryItem.unchecked) {
          countries.push(countryItem.country);
          countryItem.city.forEach((cityItem) => {
            if (cityItem.city && !cityItem.unchecked) {
              cities.push(cityItem.city);
            }
            if (cityItem.unchecked) {
              isAllCitiesChecked = false;
            }
          });
        }
      });
    }
  });

  const finalData = {
    regions,
    countries,
    cities: isAllCitiesChecked ? [] : cities,
    types: eventTypes,
  };
  if (isDateActive) {
    finalData.startDate = dayjs(startDate).format('YYYY-MM-DD');
    finalData.endDate = dayjs(endDate).format('YYYY-MM-DD');
  }
  return finalData;
}

const getAccessFilteredEventsThunkPending = (state) => {
  state.isFilteredEventsLoading = true;
};

const getAccessFilteredEventsThunkFulfilled = (state, { payload }) => {
  state.isFilteredEventsLoading = false;
  state.filterOptions.isOpen = false;
  state.filteredEvents = payload;
};

const getAccessFilteredEventsThunkRejected = (state) => {
  state.isFilteredEventsLoading = false;
};

export const accessExtraReducers = (builder) => {
  builder
    .addCase(getAccessUsersThunk.pending, getAccessUsersThunkPending)
    .addCase(getAccessUsersThunk.fulfilled, getAccessUsersThunkFulfilled)
    .addCase(deleteAccessUserThunk.fulfilled, deleteAccessUserThunkFulfilled)
    .addCase(createAccessThunk.pending, createAccessThunkPending)
    .addCase(createAccessThunk.fulfilled, createAccessThunkFulfilled)
    .addCase(createAccessThunk.rejected, createAccessThunkRejected)
    .addCase(updateAccessUserThunk.pending, updateAccessUserThunkPending)
    .addCase(updateAccessUserThunk.fulfilled, updateAccessUserThunkFulfilled)
    .addCase(updateAccessUserThunk.rejected, updateAccessUserThunkRejected)
    .addCase(getAccessEventsThunk.pending, getAccessEventsThunkPending)
    .addCase(getAccessEventsThunk.fulfilled, getAccessEventsThunkFulfilled)
    .addCase(getOnlyLocationsThunk.pending, getOnlyLocationsThunkPending)
    .addCase(getOnlyLocationsThunk.fulfilled, getOnlyLocationsThunkFulfilled)
    .addCase(getAccessFilteredEventsThunk.pending, getAccessFilteredEventsThunkPending)
    .addCase(getAccessFilteredEventsThunk.fulfilled, getAccessFilteredEventsThunkFulfilled)
    .addCase(getAccessFilteredEventsThunk.rejected, getAccessFilteredEventsThunkRejected)
    .addCase(selectAccessUserEventsThunk.pending, selectAccessUserEventsThunkPending)
    .addCase(selectAccessUserEventsThunk.fulfilled, selectAccessUserEventsThunkFulfilled)
    .addCase(selectAccessUserEventsThunk.rejected, selectAccessUserEventsThunkRejected);
};
