import { useSelector } from 'react-redux';
import FilterArea from './components/FilterArea/FilterArea';
import FilterLanguage from './components/FilterLanguage/FilterLanguage';
import FilterPatient from './components/FilterPatient/FilterPatient';
import s from './FilterLeftSection.module.scss';

const FilterLeftSection = ({ onItemClick }) => {
  const optionsLoading = useSelector(state => state.filteredEvents.optionsLoading);

  return (
    <div className={s.section}>
      <div className={s.therapeutic}>
        <FilterArea onItemClick={onItemClick} optionsLoading={optionsLoading} />
      </div>
      <div className={s.case}>
        <FilterPatient onItemClick={onItemClick} optionsLoading={optionsLoading} />
      </div>
      <div className={s.language}>
        <FilterLanguage onItemClick={onItemClick} optionsLoading={optionsLoading} />
      </div>
    </div>
  );
};

export default FilterLeftSection;
