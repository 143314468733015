import { createSlice } from '@reduxjs/toolkit';
import { questionAnalysExtraReducers } from '../thunks/questionAnalysThunk';

const initialState = {
  analys: {
    doctors: null,
    result: null,
  },
  isLoading: false,
};

export const questionAnalysSlice = createSlice({
  name: 'questionAnalys',
  initialState,
  reducers: {
    addQuestionAnalys: (state, { payload }) => { state.analys = payload; },
    resetQuestionAnalys: () => initialState,
    setQuestionLoading: (state, { payload }) => { state.isLoading = payload; },
  },
  extraReducers: questionAnalysExtraReducers,
});

// ACTIONS
export const {
  addQuestionAnalys, resetQuestionAnalys, setQuestionLoading,
} = questionAnalysSlice.actions;

// SELECTORS
export const selectQuestionAnalys = state => state.questionAnalys.events;

export default questionAnalysSlice.reducer;
