import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActive: false,
  onConfirm: null,
};

export const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    setConfirm: (state, { payload }) => {
      state.isActive = true;
      state.onConfirm = payload;
    },
    resetConfirm: () => initialState,
  },
});

// ACTIONS
export const { setConfirm, resetConfirm } = confirmSlice.actions;

// SELECTORS
export const selectConfirmInfo = state => state.confirmInfo;

export default confirmSlice.reducer;
