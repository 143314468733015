import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ Component, ...props }) => {
  const isAuth = useSelector(state => state.userInfo.isAuth);
  return (
    <Route
      {...props}
      render={({ location }) => (
        isAuth ? <Component /> : <Redirect to={{ pathname: '/signIn', state: { from: location }}} />
      )} />
  );
};

export default PrivateRoute;
