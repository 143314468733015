import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can from '../../../../components/Can/Can';
import {
  Card, Switcher,
} from '../../../../components/UI';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import { PATIENT_BASE_INFO_KEYS } from '../../../../configs/patients.configs';
import { USER_ROLES_IDS } from '../../../../constants/ids';
import { ALERT_TYPES } from '../../../../constants/names';
import { getPatientDefaultImage } from '../../../../helpers/utils';
import { setAlert } from '../../../../redux/slices/alertSlice';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import { selectRole } from '../../../../redux/slices/userInfoSlice';
import {
  deletePatientThunk, draftApprovedPatientThunk, getPatientViewIdThunk, switchPatientShareThunk,
} from '../../../../redux/thunks/patientsDataThunk';
import s from './PatientItem.module.scss';

const { SUPER_USER } = USER_ROLES_IDS;
const {
  GENDER, AGE, FIRST_NAME, HISTORY, LAST_NAME, SYMPTOMS, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const PatientItem = ({
  patient, onEventClick, isActive, setModalInfo, draft, restartFetch,
  check, onCheck, checked, isTranslations, openAddTranslation,
}) => {
  const { avatar, activeTranslation } = patient;

  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const [ isDotsDropdownActive, setIsDotsDropdownActive ] = useState(false);

  const isView = role !== SUPER_USER;
  const isShareable = patient.shareable;
  const baseInfo = patient.baseInfo || activeTranslation.baseInfo || {};

  const approveDraftPatient = (isApproved) => {
    dispatch(draftApprovedPatientThunk({ isApproved, id: patient.id, restartFetch }));
  };

  const onApproveDraftClick = (isApproved) => {
    dispatch(setConfirm(() => approveDraftPatient(isApproved)));
  };

  const onDotsClick = () => {
    setIsDotsDropdownActive(true);
  };

  const onDuplicateClick = () => {
    setModalInfo({ type: 'Add', data: patient });
    setIsDotsDropdownActive(false);
  };

  const deletePatient = () => {
    dispatch(deletePatientThunk(
      { id: patient.id, restartFetch, closeModal: () => setModalInfo(null) },
    ));
  };

  const onDeleteClick = () => {
    dispatch(setConfirm(deletePatient));
    setIsDotsDropdownActive(false);
  };

  const onEditClick = () => {
    setModalInfo({ type: 'Modify', data: patient, isView });
  };

  const onShareClick = () => {
    if (isShareable) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}case=${patient.uuid}&language=${activeTranslation.language}/`);
      dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
    } else {
      dispatch(getPatientViewIdThunk({ id: patient.id, language: activeTranslation.language }));
    }
  };

  const patientImage = avatar
    ? process.env.REACT_APP_IMAGE_URL + patient.avatar
    : getPatientDefaultImage(baseInfo.gender);

  const onLinkSwitch = (e) => {
    dispatch(switchPatientShareThunk({ id: patient.id, status: patient.shareable ? 0 : 1 }));
  };

  return (
    <div className={s.cardItem}>
      <Card
        closable
        checked={checked}
        check={check}
        onCheck={onCheck}
        active={isActive}
        onClick={() => onEventClick(patient.id)}
        actions={
          <div className={s.actions}>
            {!check && (!draft
              ? <>
                <div onClick={onShareClick} className={s.actionItem}>Share</div>
                <Can roles={[ SUPER_USER ]}>
                  <div onClick={() => onApproveDraftClick()} className={s.draftAction}>
                    Send to Draft
                  </div>
                </Can>
              </>
              : <>
                <div onClick={onEditClick} className={s.actionItem}>{isView ? 'View' : 'Edit'}</div>
                <Can roles={[ SUPER_USER ]}>
                  <div onClick={openAddTranslation} className={s.actionItem}>
                    Add Translation
                  </div>
                </Can>
                <Can roles={[ SUPER_USER ]}>
                  <div onClick={onLinkSwitch} className={classNames(s.actionItem, s.switcher)}>
                    <Switcher active={!!patient.shareable} />
                  </div>
                </Can>
                {(role === SUPER_USER || !!isShareable)
                && <div onClick={onShareClick} className={classNames(s.actionItem, s.share)}>{isShareable ? 'Share' : 'View Link'}</div>
                }
                <Can roles={[ SUPER_USER ]}>
                  <div onClick={() => onApproveDraftClick(true)} className={s.actionItem}>
                    Approve
                  </div>
                </Can>
                <Can roles={[ SUPER_USER ]}>
                  <div className={s.dotsWrapper}>
                    <div className={s.dots} onClick={onDotsClick} />
                    <Dropdown
                      toggle={setIsDotsDropdownActive}
                      active={isDotsDropdownActive}
                      onlyModal>
                      <Dropdown.Modal className={s.dotsDropdown}>
                        <div className={s.dotsDropdownList}>
                          <div
                            onClick={onDuplicateClick}
                            className={s.dotsDropdownItem}>
                            Duplicate
                          </div>
                          <div
                            onClick={onDeleteClick}
                            className={s.dotsDropdownItem}>
                            Delete
                          </div>
                        </div>
                      </Dropdown.Modal>
                    </Dropdown>
                  </div>
                </Can>
              </>)
            }
            {/* {check && <div className={s.checkbox}>
              <Checkbox checked={checked} onChange={onCheckClick} />
              </div>} */}
          </div>
        }>
        <div className={s.contentWrapper}>
          <div className={classNames(s.imageWrapper, { [s.imageWrapperActive]: isActive })}>
            <img src={patientImage} alt={patient.lastName} />
          </div>
          <div className={s.content}>
            <div className={classNames(s.patientTitle, s.bold)}>
              {isTranslations
              && <div className={s.languageIndicator}>
                  {activeTranslation.language}
                </div>}
              <div className={patient.approved === 1 ? (s.approvedEvent) : (s.draftEvent)}>
                {`${baseInfo[GENDER]} ${baseInfo[FIRST_NAME] || ''}${baseInfo[LAST_NAME] ? ` ${baseInfo[LAST_NAME]}` : ''}${baseInfo[AGE]
                  && `, ${baseInfo[AGE]}`} - ${baseInfo[VISIT]}`}
              </div>
            </div>
            {/* {isActive && <div>{GENDER_NAMES[baseInfo[GENDER]]}</div>} */}
            {baseInfo[SYMPTOMS]
            && Array.isArray(baseInfo[SYMPTOMS]) && <div>
              {baseInfo[SYMPTOMS].map((symptom, i) => <span key={i}>{symptom} </span>)}
              </div>
            }
            {isActive && baseInfo[HISTORY]
            && Array.isArray(baseInfo[SYMPTOMS]) && <div>
              {baseInfo[HISTORY].map((diagnos, i) => <span key={i}>{diagnos} </span>)}
              </div>
            }
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PatientItem;
