import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EVENT_KEYS } from '../../../../../../constants/names';
import PatientItemShort from '../../../../../../pages/PatientCases/components/PatientItem/PatientItemShort';
import { getFilterLanguagesThunk } from '../../../../../../redux/thunks/filteredEventsThunk';
import FilterItemList from '../../../FilterItemList/FilterItemList';

const { CASE_LIST } = EVENT_KEYS;

const FilterPatient = ({ onItemClick }) => {
  const dispatch = useDispatch();
  const selectedCases = useSelector(state => state.filteredEvents.filters[CASE_LIST]);
  const allCases = useSelector(state => state.filteredEvents.allItems[CASE_LIST]);
  const isPatientsLoading = useSelector(state => state.filteredEvents.isPatientsLoading);

  const [ activePatients, setActivePatients ] = useState([]);

  const onCaseClick = (id) => {
    if (selectedCases.includes(id) && selectedCases.length === 1) return;
    onItemClick(CASE_LIST, id, false, true);
  };

  const onPatientArrowClick = (id) => {
    if (activePatients.includes(id)) {
      return setActivePatients(activePatients.filter(item => item !== id));
    }
    return setActivePatients(prev => [ ...prev, id ]);
  };

  useEffect(() => {
    dispatch(getFilterLanguagesThunk(selectedCases));
  }, [ selectedCases ]);

  return (
    <FilterItemList
      cases
      radio
      length={allCases.length}
      loading={isPatientsLoading}
      onSelectAll={() => onItemClick(CASE_LIST, null, true)}
      isAllChecked={selectedCases.length === allCases.length}
      title='Patient Case'>
      {allCases.map(item => (
        <PatientItemShort
          key={item.id}
          patient={item}
          check
          isActive={activePatients.includes(item.id)}
          checked={selectedCases.includes(item.id)}
          onEventClick={onPatientArrowClick}
          onCheck={() => onCaseClick(item.id)} />
      ))}
    </FilterItemList>
  );
};

export default FilterPatient;
