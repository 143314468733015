import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { EVENT_KEYS } from '../../../../constants/names';
import { Datepicker, Radio } from '../../../UI';
import s from './FilterDateSection.module.scss';
import { toggleDateActivity } from '../../../../redux/slices/filteredEventsSlice';

const { DATE_FROM, DATE_TILL } = EVENT_KEYS;

const DATE_SWITCHER_NAMES = [ 'No Date Range', 'Specific Date Range' ];

const FilterDateSection = ({ onItemClick }) => {
  const dispatch = useDispatch();
  const startDate = useSelector(state => state.filteredEvents.filters[DATE_FROM]);
  const endDate = useSelector(state => state.filteredEvents.filters[DATE_TILL]);
  const isDateActive = useSelector(state => state.filteredEvents.filters.isDateActive);

  const toggleDateActive = (value) => {
    dispatch(toggleDateActivity(!!value));
  };

  return (
    <div className={s.dateBoard}>
      <div className={s.dateSwitcher}>
        {DATE_SWITCHER_NAMES.map((item, i) => (
          <div
            key={item}
            className={classNames(s.dateSwitchItem, {
              [s.activeDateState]: isDateActive === !!i,
            })}
            onClick={() => toggleDateActive(i)}>
            <div>{item}</div>
            <div><Radio active={isDateActive === !!i} size='22px' /></div>
          </div>
        ))}
      </div>
      <div className={classNames(s.datepickers, { [s.dissable]: !isDateActive })}>
        <div className={s.datepicker}>
          <div>
            <Datepicker
              selectsStart
              clear
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              popperPlacement="top"
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [ 0, -10 ],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    mainAxis: false,
                  },
                },
              ]}
              onChange={(date) => {
                onItemClick(DATE_FROM, date);
                if (!endDate || (Date.parse(endDate) < Date.parse(date))) {
                  onItemClick(DATE_TILL, date);
                }
              }} />
          </div>
          <div className={s.grayRow}><div>Date (on / from)</div></div>
        </div>
        <div className={s.datepicker}>
          <div>
            <Datepicker
              selectsEnd
              clear
              selected={endDate}
              minDate={startDate || Date.now()}
              startDate={startDate}
              endDate={endDate}
              popperPlacement="top"
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [ 0, -10 ],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    mainAxis: false,
                  },
                },
              ]}
              onChange={date => onItemClick(DATE_TILL, date)} />
          </div>
          <div className={s.grayRow}>Date (till)</div>
        </div>
        {!isDateActive && <div className={s.datepickersBackground} />}

      </div>
    </div>
  );
};

export default FilterDateSection;
