import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET FILTERS OPTIONS
export const getGeneralAnalysThunk = createAsyncThunk(
  'generalAnalys/getGeneralAnalys',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/analytics/general', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getGeneralAnalysThunkPending = (state) => {
  state.isLoading = true;
};

const getGeneralAnalysThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.analys = payload;
};

export const generalAnalysExtraReducers = (builder) => {
  builder
    .addCase(getGeneralAnalysThunk.pending, getGeneralAnalysThunkPending)
    .addCase(getGeneralAnalysThunk.fulfilled, getGeneralAnalysThunkFulfilled);
};
