import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import FilterCity from '../../../../../../components/FiltersModal/components/FilterLocationSection/components/FilterCity/FilterCity';
import FilterCountry
from '../../../../../../components/FiltersModal/components/FilterLocationSection/components/FilterCountry/FilterCountry';
import FilterRegion
from '../../../../../../components/FiltersModal/components/FilterLocationSection/components/FilterRegion/FilterRegion';
import { CheckboxPure } from '../../../../../../components/UI';
import { EVENT_TYPE_IDS } from '../../../../../../constants/ids';
import { EVENT_TYPE_NAMES } from '../../../../../../constants/names';
import {
  selectAccessFilterOptions, toggleAccessCity, toggleAccessCountry,
  toggleAccessEventTypes, toggleAccessRegion,
} from '../../../../../../redux/slices/accessSlice';
import s from './LocationSection.module.scss';

const eventTypes = Object.values(EVENT_TYPE_IDS);

const LocationSection = ({ allRegions, allCountries, allCities }) => {
  const dispatch = useDispatch();
  const { eventTypes: activeEventTypes, isLocationsLoading } = useSelector(selectAccessFilterOptions);

  const onTypeClick = (value) => {
    dispatch(toggleAccessEventTypes(value));
  };

  const onRegionItemClick = (data) => {
    dispatch(toggleAccessRegion(data));
  };

  const onCountryItemClick = (data) => {
    dispatch(toggleAccessCountry(data));
  };

  const onCityItemClick = (data) => {
    dispatch(toggleAccessCity(data));
  };

  return (
    <div className={s.section}>
      <div className={s.listBoard}>
        <div>
          <FilterRegion allRegions={allRegions} isLoading={isLocationsLoading} onItemClick={onRegionItemClick} />
        </div>

        <div>
          <FilterCountry allCountries={allCountries} isLoading={isLocationsLoading} onItemClick={onCountryItemClick} />
        </div>
        <div>
          <FilterCity allCities={allCities} isLoading={isLocationsLoading} onItemClick={onCityItemClick} />
        </div>
      </div>
      <div className={s.eventBoard}>
        <div>Event Type</div>
        <div className={s.eventTypes}>
          {eventTypes.map((item) => {
            const isTypeDisabled = activeEventTypes.length === 1 && activeEventTypes[0] === item;
            return (
              <div
                onClick={() => !isTypeDisabled && onTypeClick(item)}
                className={classNames(s.eventType, { [s.disabled]: isTypeDisabled })}
                key={item}>
                <div>{EVENT_TYPE_NAMES[item]}</div>
                <div className={s.typeCheckbox}>
                  <CheckboxPure disabled={isTypeDisabled} checked={activeEventTypes.includes(item)} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
