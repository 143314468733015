import { useDispatch, useSelector } from 'react-redux';
import { resetConfirm, selectConfirmInfo } from '../../redux/slices/confirmSlice';
import { Button } from '../UI';
import s from './ConfirmModal.module.scss';

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const { isActive, onConfirm } = useSelector(selectConfirmInfo);

  const onCloseClick = () => dispatch(resetConfirm());

  const onConfirmClick = () => {
    onConfirm();
    onCloseClick();
  };

  if (!isActive) return null;

  return (
    <div onClick={onCloseClick} className={s.background}>
      <div onClick={e => e.stopPropagation()} className={s.modal}>
        <div className={s.question}><div>Are you sure?</div></div>
        <div className={s.actions}>
          <Button variant='light' onClick={onCloseClick}>Cancel</Button>
          <Button onClick={onConfirmClick}>Confirm</Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
