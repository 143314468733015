import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Heading from '../../components/Heading/Heading';
import s from './Access.module.scss';
import AccessEvents from './components/AccessEvents/AccessEvents';
import AccessList from './components/AccessList/AccessList';
import { getOnlyLocationsThunk } from '../../redux/thunks/accessThunk';
import { resetAccessSlice, selectIsAccessFilterOpen, toggleCreateModal } from '../../redux/slices/accessSlice';
import AccessFilterModal from './components/AccessFilter/AccessFilterModal';

const Access = ({ isModerator }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectIsAccessFilterOpen);

  useEffect(() => {
    dispatch(getOnlyLocationsThunk());
    return () => dispatch(resetAccessSlice());
  }, []);

  return (
    <div className={s.root}>
      <Heading />
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className={classNames('card_holder', s.accessRootContainer)}>
            <div className={classNames('cards')}>
              <div className={s.container}>
                <div className={s.content}>
                  <div className={s.accessList}>
                    <AccessList />
                  </div>
                  <div className={s.accessEvents}>
                    <AccessEvents />
                  </div>
                </div>
                <div className={s.actionItems}>
                  <div className={s.createUserBtn} onClick={() => dispatch(toggleCreateModal(true))} />
                </div>
              </div>
              {isModalOpen && <AccessFilterModal />}
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Access;
