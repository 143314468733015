import React from 'react';
import ReactContentLoader from 'react-content-loader';

const ContentLoader = ({ color = 'var(--color-gray3)', radius = '0.375rem', ...props }) => (
  <ReactContentLoader
    speed={1}
    width={'100%'}
    height={'100%'}
    backgroundColor={color}
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx={radius} ry={radius} width="100%" height="100%" />
  </ReactContentLoader>
);

export default ContentLoader;
