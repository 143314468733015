/* eslint-disable react-hooks/rules-of-hooks */
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useOutsideClick } from '../../../../../../hooks/useOutsideClick';
import s from './DropDown.module.scss';

const Dropdown = ({
  active, value, className, rootClass, children, toggle, onlyModal, isView, ...props
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => toggle(false), active);

  useEffect(() => {
    if (active) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [ active ]);

  return (
    <div ref={ref} className={classNames(s.dropdownRoot, { [rootClass]: rootClass })}>
      {!onlyModal && <div
        onClick={() => toggle(prev => !prev)}
        className={classNames(s.dropdown, { [s.dropdownActive]: active, [className]: className })
        }
        {...props}>
        <div>{value}</div>
        {!isView && <div className={s.arrowWrapper}>
          <div className={s.arrow} />
        </div>}
      </div>}
      {active && children}
    </div>
  );
};

Dropdown.Modal = ({ children, className }) => (
  <div className={classNames(s.dropdownModal, {
    [className]: className,
  })}>
    {children}
  </div>
);

export default Dropdown;
