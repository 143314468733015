import { useSelector } from 'react-redux';
import Heading from '../../../components/Heading/Heading';
import FilterCard from '../../../components/FilterCard/FilterCard';
import PastAnalysisList from './components/PastAnalysisList/PastAnalysisList';
import s from './PastEventAnalysis.module.scss';
import NoItem from '../../../components/NoItem/NoItem';

const PastEventAnalysis = () => {
  const events = useSelector(state => state.filteredEvents.eventsData.events);

  return (
    <div className={s.pastAnalysis}>
      <Heading>
        <FilterCard />
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className="cards">
              {
                events.length
                  ? <PastAnalysisList />
                  : <div className={s.noEvent}><NoItem analys /></div>
                      }
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default PastEventAnalysis;
