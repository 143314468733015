import { createSlice } from '@reduxjs/toolkit';
import { patientsModalExtraReducers } from '../thunks/patientsModalThunk';

const initialState = {
  isOpen: false,
  patients: [],
  isLoading: true,
};

export const patientsModalSlice = createSlice({
  name: 'patientsData',
  initialState,
  reducers: {
    resetPatientsModal: () => initialState,
    togglePatientsModal: (state, { payload }) => {
      state.isOpen = payload;
    },
    togglePatientLanguage: (state, { payload }) => {
      const { id, language } = payload;
      const currentPatientIndex = state.patients.findIndex(patient => patient.id === id);
      if (currentPatientIndex !== -1) {
        state.patients[currentPatientIndex].activeTranslation = language;
      }
    },
  },
  extraReducers: patientsModalExtraReducers,
});

// ACTIONS
export const { resetPatientsModal, togglePatientsModal, togglePatientLanguageFromModal } = patientsModalSlice.actions;

// SELECTORS
export const selectPatientsFromModal = state => state.patientsModal.patients;

export default patientsModalSlice.reducer;
