import FilterItemList from '../../../FilterItemList/FilterItemList';

const FilterCountry = ({
  allCountries, isDisabled, isLoading, onItemClick,
}) => {
  const isAllChecked = allCountries.visibleCount === allCountries.checkCount;

  return (
    <FilterItemList
      disabled={isDisabled}
      length={allCountries.visibleCount}
      loading={isLoading}
      onSelectAll={() => onItemClick({ all: true, isAllChecked })}
      isAllChecked={isAllChecked}
      title='Country'>
      {allCountries.items.map((item) => {
        if (item.invisible) return null;
        return (
          <FilterItemList.Item
            key={item.name + item.regionIndex + item.index}
            value={item.name}
                // disabled={allCountries.length === 1}
            checked={!item.unchecked}
            onClick={() => onItemClick(item)} />
        );
      })}
    </FilterItemList>
  );
};

export default FilterCountry;
