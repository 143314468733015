import React from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import s from './CarouselPopup.module.scss';
import nextArrow from '../../assets/images/nextArrow.svg';
import prevArrow from '../../assets/images/prevArrow.svg';

const CarouselPopup = ({
  files, smallCarousel, onClose,
}) => (
  <div className={s.carouselPopup}>
    <div
      className={s.carouselPopupClose}
      onClick={() => {
        onClose();
      }} />
    <div className={classNames(s.carouselPopup__inner, smallCarousel && s.smallCarousel)}>
      <div className={s.carouselPopup__pic}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows
          renderArrowPrev={(clickHandler, hasPrev, labelPrev) => hasPrev && (
            <button className={classNames(s.carouselPopup__sliderButton, s.carouselPopup__sliderButton__prevPos)} onClick={clickHandler}>
              <img
                className={s.carouselPopup__sliderButton__img}
                src={prevArrow}
                alt='previous' />
            </button>
          )
          }
          renderArrowNext={(clickHandler, hasNext, labelNext) => hasNext && (
            <button className={classNames(s.carouselPopup__sliderButton, s.carouselPopup__sliderButton__nextPos)} onClick={clickHandler}>
              <img
                className={s.carouselPopup__sliderButton__img}
                src={nextArrow}
                alt='next' />
            </button>
          )
          }
          autoFocus
          showArrows>
          {files.map(
            item => (Object.prototype.hasOwnProperty.call(item, 'mozJpeg') ? (
              <div className={s.carouselPopup__imgContainer} key={item.name}>
                <picture className={s.carouselPopup__img}>
                  <source srcSet={process.env.REACT_APP_IMAGE_URL + item.webp} type='image/webp' />
                  <source srcSet={process.env.REACT_APP_IMAGE_URL + item.mozJpeg} type='image/jpeg' />
                  <img
                    className={s.carouselPopup__img}
                    src={process.env.REACT_APP_IMAGE_URL + item.mozJpeg}
                    alt='' />
                </picture>
              </div>
            ) : (
              <div key={item.name}>
                <ReactPlayer
                  url={process.env.REACT_APP_IMAGE_URL + item.videoUrl}
                  className='react-player fixed-bottom'
                  width='100%'
                  height='100%'
                  controls />
              </div>
            )),
          )}
        </Carousel>
        <div
          className={s.carouselPopup__close}
          onClick={e => onClose()}>
          <svg className={s.carouselPopup__closeSvg}>
            <use xlinkHref="#close" />
          </svg>
        </div>
      </div>
    </div>
  </div>
);

export default CarouselPopup;
