import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET FILTERS OPTIONS
export const getQuestionAnalysThunk = createAsyncThunk(
  'questionAnalys/getQuestionAnalys',
  async (data, { dispatch, getState }) => {
    const response = await AXIOS.post('/analytics/questions', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data || { doctors: null, result: null };
  },
);

const getQuestionAnalysThunkPending = (state) => {
  state.isLoading = true;
};

const getQuestionAnalysThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.analys = payload;
};

export const questionAnalysExtraReducers = (builder) => {
  builder
    .addCase(getQuestionAnalysThunk.pending, getQuestionAnalysThunkPending)
    .addCase(getQuestionAnalysThunk.fulfilled, getQuestionAnalysThunkFulfilled);
};
