import FilterItemList from '../../../FilterItemList/FilterItemList';

const FilterCity = ({
  allCities, isDisabled, isLoading, onItemClick,
}) => {
  const isAllChecked = allCities.visibleCount === allCities.checkCount;

  return (
    <FilterItemList
      disabled={isDisabled}
      length={allCities.visibleCount}
      loading={isLoading}
      onSelectAll={() => onItemClick({ all: true, isAllChecked })}
      isAllChecked={isAllChecked}
      title='City'>
      {allCities.items.map((item, i) => {
        if (item.invisible) return null;
        return (
          <FilterItemList.Item
            key={item.name + item.regionIndex + item.countryIndex + item.index}
        // disabled={allCities.length === 1}
            value={item.name}
            checked={!item.unchecked}
            onClick={() => onItemClick(item)} />
        );
      })}
    </FilterItemList>
  );
};

export default FilterCity;
