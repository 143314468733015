import FilterItemList from '../../../FilterItemList/FilterItemList';

const FilterRegion = ({
  allRegions, isDisabled, isLoading, onItemClick,
}) => {
  const isAllChecked = allRegions.count === allRegions.checkCount;

  return (
    <FilterItemList
      disabled={isDisabled}
      length={allRegions.count}
      loading={isLoading}
      onSelectAll={() => onItemClick({ all: true, isAllChecked })}
      isAllChecked={isAllChecked}
      title='Region'>
      {allRegions.items.map(item => (
        <FilterItemList.Item
          key={item.name}
          value={item.name}
                // disabled={allRegions.length === 1}
          checked={!item.unchecked}
          onClick={() => onItemClick(item)} />
      ))}
    </FilterItemList>
  );
};

export default FilterRegion;
