import classNames from 'classnames';
import s from './Checkbox.module.scss';

const CheckboxPure = ({ checked, big, disabled }) => (
  <div className={classNames(s.dropdownRow__checkbox, { [s.bigCheckbox]: big, [s.disabled]: disabled })}>
    <div className={classNames(s.dropdownRow__noted, { [s.dropdownRow__noted_active]: checked })} />
  </div>
);

export default CheckboxPure;
