import { useSelector } from 'react-redux';
import { ContentLoader } from '../../../../../components/UI';
import s from './StatisticItem.module.scss';

const StatisticItem = ({ info, description }) => {
  const { analys, isLoading } = useSelector(state => state.generalAnalys);
  const isEventsLoading = useSelector(state => state.filteredEvents.eventsData.isEventsLoading);

  const count = analys[info.key] ?? '-';

  return (
    <div className={s.root}>
      <div>{info.title}</div>
      <div className={s.info}>{(isLoading || isEventsLoading) ? <ContentLoader /> : count}</div>
      {description}
    </div>
  );
};

export default StatisticItem;
