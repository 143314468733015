import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select } from '../../../../components/UI';
import Modal from '../../../../components/UI/Modal/Modal';
import { EVENT_CONFIGS_KEYS } from '../../../../configs/events.configs';
import { getPatientDefaultImage, modifySelectOptions } from '../../../../helpers/utils';
import { addTranslationThunk, importPatientThunk } from '../../../../redux/thunks/patientsDataThunk';
import PatientAvatarSection from '../PatientCasesModal/components/PatientAvatarSection';
import s from './AddTranslationModal.module.scss';

const { LANGUAGE } = EVENT_CONFIGS_KEYS;

const AddTranslationModal = ({ patient, closeModal, restartFetch }) => {
  const dispatch = useDispatch();
  const eventConfigs = useSelector(state => state.userInfo.eventConfigs.configs);
  const isImportLoading = useSelector(state => state.patientsData.isImportLoading);

  const [ sheet, setSheet ] = useState('');
  const [ baseInfo, setBaseInfo ] = useState(null);
  const [ isAddLoading, setIsAddLoading ] = useState(false);

  const languageOptions = useMemo(() => {
    const translations = patient.translations.map(item => item.language);
    return modifySelectOptions(eventConfigs[LANGUAGE].filter(lang => !translations.includes(lang)));
  }, [ eventConfigs[LANGUAGE] ]);
  const [ languageSelect, setLanguageSelect ] = useState(() => null);

  const onImportClick = () => {
    dispatch(importPatientThunk({
      language: languageSelect.value,
      sheetURL: sheet,
      id: patient.id || -1,
    }))
      .unwrap()
      .then(res => setBaseInfo(res));
  };

  const onOkClick = async () => {
    try {
      setIsAddLoading(true);
      await dispatch(addTranslationThunk({
        id: patient.id,
        language: languageSelect.value,
        restartFetch,
        sheetURL: sheet,
        closeModal,
      })).unwrap();
    } finally {
      setIsAddLoading(false);
    }
  };

  const avatar = patient.avatar
    ? process.env.REACT_APP_IMAGE_URL + patient.avatar
    : getPatientDefaultImage(patient.activeTranslation.baseInfo.gender);

  const isImportDisabled = !sheet || !languageSelect;

  return (
    <Modal
      title='Add Translation'
      actions={
        <div className={s.actions}>
          <div>
            <Button
              className={s.cancelButton}
              onClick={closeModal}
              variant={'light'}>Cancel</Button>
            <Button
              loading={isAddLoading}
            // disabled={isOkDisable}
              onClick={onOkClick}
            >
              OK
            </Button>
          </div>
        </div>
  }>
      <div className={s.content}>
        <div className={s.section}>
          <div className={s.row}>
            <div>Language<span className={s.required}>*</span>:</div>
            <div>
              <Select
                isSearchable
                placeholder='Select Language...'
                value={languageSelect}
                onChange={value => setLanguageSelect(value)}
                options={languageOptions} />
            </div>
          </div>
          <div className={s.row}>
            <div>Input File<span className={s.required}>*</span>:</div>
            <div
          ><textarea
            className={s.textarea}
            name='sheet'
            onChange={({ target }) => setSheet(target.value)}
            value={sheet} />
            </div>
          </div>
          <div className={s.sectionActions}>
            <Button
              disabled={!sheet}
              onClick={() => setSheet('')}
              className={classNames(s.sectionActions_button, s.clearButton)}>
              Clear
            </Button>
            <Button
              disabled={isImportDisabled}
              className={s.importButton}
              loading={isImportLoading}
              onClick={onImportClick}>Verify and Import</Button>
          </div>
        </div>
        <div className={classNames(s.section, s.lastSection)}>
          <PatientAvatarSection view isImageHave avatar={avatar} baseInfo={baseInfo} />
        </div>
      </div>
    </Modal>
  );
};

export default AddTranslationModal;
