import React from 'react';
import ReactSelect from 'react-select';

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1.125rem',
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    // width: state.selectProps.width,
    opacity: state.isDisabled ? '0.7' : '1',
    boxShadow: 'none',
    background: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '3.06rem',
    height: '3.06rem',
    fontSize: '1.125rem',
    fontWeight: '500',
    cursor: 'pointer',
    border: 'none',
    borderRadius: 'none',
    padding: '0.5rem',
    color: 'black',
    '&:hover': {
      borderColor: 'inherit',
    },
  }),
  indicatorSeparator: () => ({}),
  placeholder: provided => ({
    display: 'none',
  }),
  dropdownIndicator: (_, state) => ({
    display: 'none',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '2px 8px',
  }),
};

const CustomSelect = ({
  label, style = {}, width = '9.8rem', ...props
}) => (
  <ReactSelect
  // TODO fix indicator rotation
    hideSelectedOptions={false}
    styles={customStyles}
    isSearchable={false}
    isClearable={false}
    controlShouldRenderValue={false}
    width={width}
    {...props}
     />
);

export default CustomSelect;
