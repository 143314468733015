import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLES_IDS } from '../../constants/ids';
import { USER_ROLES_NAMES } from '../../constants/names';
import s from './Header.module.scss';
import { selectModeratorActiveEvent } from '../../redux/slices/moderatorSlice';

const Header = () => {
  const isAuth = useSelector(state => state.userInfo.isAuth);
  const role = useSelector(state => state.userInfo.role);
  const activeEvent = useSelector(selectModeratorActiveEvent);

  return (
    <header className={s.header}>
      <div className={s.headerInfo}>
        {isAuth && (
          <div className={s.headerPharmaceutical}>
            <div className={s.headerPharmaceutical__icon}>
              {activeEvent && activeEvent?.eventLogo && (
                <img
                  className={s.headerPharmaceutical__svg}
                  src={`${process.env.REACT_APP_IMAGE_URL}${activeEvent?.eventLogo}`}
                  alt='BoehringerIngelheim'
                />
              )}
            </div>
            <div
              className={classNames(s.userRole, {
                [s.superUser]: role === USER_ROLES_IDS.SUPER_USER,
              })}>
              {USER_ROLES_NAMES[role]}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
