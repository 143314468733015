import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET ALL DICTIONARIES
export const getDictionariesThunk = createAsyncThunk(
  'dictionary/getAll',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/dictionary/getAll').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getDictionariesThunkPending = (state) => {
  state.isDictionariesLoading = true;
};

const getDictionariesThunkFuldilled = (state, { payload }) => {
  state.isDictionariesLoading = false;
  const localDictionaries = state.dictionaries.filter(item => item.new);
  state.dictionaries = [ ...payload, ...localDictionaries ];
};

const getDictionariesThunkRejected = (state) => {
  state.isDictionariesLoading = false;
};

// CREATE DICTIONARY
export const createUpdateDictionaryThunk = createAsyncThunk(
  'dictionary/create',
  async (data, { dispatch }) => {
    const isNew = data.new;
    const response = await AXIOS.post(`/dictionary/${isNew ? 'create' : 'updateBySheet'}`, {
      language: data.name,
      sheetId: data.sheetURL,
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(setAlert({ message: 'SUCCESS', type: 'success' }));
    if (isNew) {
      dispatch(getDictionariesThunk());
    }
    return response.data;
  },
);

const createUpdateDictionaryThunkPending = (state, { meta }) => {
  const data = meta.arg;
  const currentDictionary = state.dictionaries.find(item => item.name === data.name);
  if (currentDictionary) {
    currentDictionary.loading = true;
  }
};

const createUpdateDictionaryThunkFuldilled = (state, { meta }) => {
  const data = meta.arg;
  const currentDictionary = state.dictionaries.find(item => item.name === data.name);
  if (currentDictionary) {
    currentDictionary.loading = false;
    currentDictionary.new = false;
  }
};

const createUpdateDictionaryThunkRejected = (state, { meta }) => {
  const data = meta.arg;
  const currentDictionary = state.dictionaries.find(item => item.name === data.name);
  if (currentDictionary) {
    currentDictionary.loading = false;
  }
};

// CREATE DICTIONARY
export const deleteDictionaryThunk = createAsyncThunk(
  'dictionary/delete',
  async ({ id }, { dispatch }) => {
    const response = await AXIOS.delete('/dictionary/delete', {
      params: { id },
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(setAlert({ message: 'SUCCESS', type: 'success' }));
    return response.data;
  },
);

const deleteDictionaryThunkPending = (state, { meta }) => {
  const data = meta.arg;
  const currentDictionary = state.dictionaries.find(item => item.name === data.name);
  if (currentDictionary) {
    currentDictionary.loading = true;
  }
};

const deleteDictionaryThunkFuldilled = (state, { meta }) => {
  const data = meta.arg;
  state.dictionaries = state.dictionaries.filter(item => item.name !== data.name);
};

const deleteDictionaryThunkRejected = (state, { meta }) => {
  const data = meta.arg;
  const currentDictionary = state.dictionaries.find(item => item.name === data.name);
  if (currentDictionary) {
    currentDictionary.loading = false;
  }
};

export const dictionarySettingsExtraReducers = (builder) => {
  builder
    .addCase(getDictionariesThunk.pending, getDictionariesThunkPending)
    .addCase(getDictionariesThunk.fulfilled, getDictionariesThunkFuldilled)
    .addCase(getDictionariesThunk.rejected, getDictionariesThunkRejected)
    .addCase(createUpdateDictionaryThunk.pending, createUpdateDictionaryThunkPending)
    .addCase(createUpdateDictionaryThunk.fulfilled, createUpdateDictionaryThunkFuldilled)
    .addCase(createUpdateDictionaryThunk.rejected, createUpdateDictionaryThunkRejected)
    .addCase(deleteDictionaryThunk.pending, deleteDictionaryThunkPending)
    .addCase(deleteDictionaryThunk.fulfilled, deleteDictionaryThunkFuldilled)
    .addCase(deleteDictionaryThunk.rejected, deleteDictionaryThunkRejected);
};
