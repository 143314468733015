import { createSlice } from '@reduxjs/toolkit';
import { moderatorExtraReducers } from '../thunks/moderatorThunk';

const initialState = {
  events: [],
  activeEvent: null,
  isLoading: true,
  error: null,
};

export const moderatorSlice = createSlice({
  name: 'moderator',
  initialState,
  reducers: {
    resetModerator: () => initialState,
    setModeratorActiveEvent: (state, { payload }) => { state.activeEvent = payload; },
  },
  extraReducers: moderatorExtraReducers,
});

// ACTIONS
export const { resetModerator, setModeratorActiveEvent } = moderatorSlice.actions;

// SELECTORS
export const selectModerator = state => state.moderator;
export const selectModeratorEvents = state => state.moderator.events;
export const selectModeratorActiveEvent = state => state.moderator.activeEvent;

export default moderatorSlice.reducer;
