import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { AXIOS } from '../../api/axios';
import { USER_ROLES_IDS } from '../../constants/ids';
import { EVENT_KEYS } from '../../constants/names';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

const {
  EVENT_TYPE, CASE_LIST, DATE_FROM, DATE_TILL, AREA, LANGUAGES, LOCATIONS,
} = EVENT_KEYS;

// GET FILTER AREAS
export const getFilterAreasThunk = createAsyncThunk(
  'filteredEvents/getFilterAreas',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/filter/getThAreas').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });

    return response.data;
  },
);

const getFilterAreasThunkPending = (state) => {
  state.isAreasLoading = true;
  state.isPatientsLoading = true;
  state.isLanguagesLoading = true;
  state.isLocationsLoading = true;
};

const getFilterAreasThunkFulfilled = (state, { payload }) => {
  state.isAreasLoading = false;
  state.isPatientsLoading = false;
  state.isLanguagesLoading = false;
  state.isLocationsLoading = false;
  if (payload) {
    const areas = payload.map(item => item.name);
    state.allItems[AREA] = areas;
    if (areas.length === 1) {
      state.filters[AREA] = areas;
    }
  }
};

// GET FILTER CASES
export const getFilterCasesThunk = createAsyncThunk(
  'filteredEvents/getFilterCases',
  async (areas, { dispatch }) => {
    if (!areas.length) return [];
    const response = await AXIOS.post('filter/getCases', { areas }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });

    return response.data;
  },
);

const getFilterCasesThunkPending = (state) => {
  state.isPatientsLoading = true;
  state.isLanguagesLoading = true;
  state.isLocationsLoading = true;
};

const getFilterCasesThunkFulfilled = (state, { payload }) => {
  state.isPatientsLoading = false;
  state.isLanguagesLoading = false;
  state.isLocationsLoading = false;
  if (payload) {
    state.allItems[CASE_LIST] = payload;
    state.filters[CASE_LIST] = payload.length ? [ payload[0].id ] : [];
  }
};

// GET FILTER LANGUAGES
export const getFilterLanguagesThunk = createAsyncThunk(
  'filteredEvents/getFilterLanguages',
  async (cases, { dispatch, getState }) => {
    if (!cases.length) return [];
    const caseId = cases[0];
    const areas = getState().filteredEvents.filters[AREA];
    const response = await AXIOS.post('filter/getLanguages', { areas, caseId }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });

    return response.data;
  },
);

const getFilterLanguagesThunkPending = (state) => {
  state.isLanguagesLoading = true;
  state.isLocationsLoading = true;
};

const getFilterLanguagesThunkFulfilled = (state, { payload }) => {
  state.isLanguagesLoading = false;
  state.isLocationsLoading = false;
  if (payload) {
    const languages = payload.map(item => item.language);
    state.allItems[LANGUAGES] = languages;
    state.filters[LANGUAGES] = languages;
  }
};

// GET FILTER REGION COUNTRY CITY
export const getFilterLocationsThunk = createAsyncThunk(
  'filteredEvents/getFilterLocations',
  async (languages, { dispatch, getState }) => {
    if (!languages.length) return [];
    const { filters } = getState().filteredEvents;
    const areas = filters[AREA];
    const caseId = filters[CASE_LIST][0];
    const response = await AXIOS.post('filter/getOtherInfo', { areas, caseId, languages }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getFilterLocationsThunkPending = (state) => {
  state.isLocationsLoading = true;
};

const getFilterLocationsThunkFulfilled = (state, { payload }) => {
  state.isLocationsLoading = false;
  if (payload) {
    state.allItems[LOCATIONS] = payload;
  }
};

// GET FILTERS OPTIONS
export const getFiltereOptionsThunk = createAsyncThunk(
  'filteredEvents/getFiltereOptions',
  async (_, { dispatch, getState }) => {
    const { role } = getState().userInfo;
    const response = await AXIOS.get('/analytics/filterOptions').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return { ...response.data, role };
  },
);

const getFiltereOptionsThunkPending = (state) => {
  state.optionsLoading = true;
};

const getFiltereOptionsThunkFulfilled = (state, { payload }) => {
  state.optionsLoading = false;
  const {
    events, eventType, role, doctorCounts, cases, ...rest
  } = payload;
  const restKeys = Object.keys(rest);
  restKeys.forEach((key) => {
    const currentFilter = rest[key];
    state.allItems[key] = rest[key];
    if (Array.isArray(state.filters[key]) && state.filters[key].length) {
      state.filters[key] = state.filters[key].filter(item => state.allItems[key].includes(item));
    }

    if (currentFilter?.length === 1) {
      state.filters[key] = rest[key];
    }

    if (role === USER_ROLES_IDS.EVENT_MODERATOR) {
      state.filters[key] = rest[key];
    }
  });
  state.allItems[CASE_LIST] = cases;
  if (cases && cases.length === 1) {
    state.filters[CASE_LIST] = [ cases[0].id ];
  }
  if (events) {
    state.filters.events = events;
    state.filters.checkedEvents = events.map(item => item.id);
  }
  // if (therapeuticArea) {
  //   const
  //   state.filters[AREA] = [ therapeuticArea ];
  // }
  if (eventType) {
    state.filters[EVENT_TYPE] = eventType;
  }
  if (doctorCounts !== undefined) {
    state.filters.doctorCounts = doctorCounts;
  }
};

// GET FILTERED EVENTS
export const getFilteredEventsThunk = createAsyncThunk(
  'filteredEvents/getFilteredEvents',
  async (_, { dispatch, getState }) => {
    const finalData = formatFilterData(getState().filteredEvents);
    const response = await AXIOS.post('/filter/getEvents', finalData).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

function formatFilterData(allData) {
  const { filters, allItems } = allData;

  const regions = [];
  const countries = [];
  const cities = [];
  let isAllCitiesChecked = true;

  allItems[LOCATIONS].forEach((regionItem) => {
    if (!regionItem.unchecked) {
      regions.push(regionItem.region);
      regionItem.country.forEach((countryItem) => {
        if (!countryItem.unchecked) {
          countries.push(countryItem.country);
          countryItem.city.forEach((cityItem) => {
            if (cityItem.city && !cityItem.unchecked) {
              cities.push(cityItem.city);
            }
            if (cityItem.unchecked) {
              isAllCitiesChecked = false;
            }
          });
        }
      });
    }
  });

  const finalData = {
    caseId: filters[CASE_LIST][0],
    areas: filters[AREA],
    languages: filters[LANGUAGES],
    regions,
    countries,
    cities: isAllCitiesChecked ? [] : cities,
    types: filters[EVENT_TYPE],
  };
  if (filters.isDateActive) {
    finalData.startDate = filters[DATE_FROM] && dayjs(filters[DATE_FROM]).format('YYYY-MM-DD');
    finalData.endDate = filters[DATE_TILL] && dayjs(filters[DATE_TILL]).format('YYYY-MM-DD');
  }
  return finalData;
}

const getFilteredEventsThunkPending = (state) => {
  state.eventsData.isEventsLoading = true;
};

const getFilteredEventsThunkFulfilled = (state, { payload }) => {
  state.eventsData = {
    events: payload.events,
    eventsIds: payload.events.map(event => event.id),
    doctors: payload.count,
    isEventsLoading: false,
  };
  state.isOpen = false;
};
// export const getFilteredEventsThunk = createAsyncThunk(
//   'filteredEvents/getFilteredEvents',
//   async (_, { dispatch, getState }) => {
//     const { filters, allItems, isDateActive } = getState().filteredEvents;
//     const finalData = {};
//     Object.keys(filters).forEach((key) => {
//       if (isDateActive && key === DATE_TILL || key === DATE_FROM) {
//         finalData[key] = dayjs(filters[key]).format('YYYY-MM-DD');
//       } else if (Array.isArray(allItems[key]) && allItems[key].length === filters[key].length) {
//         finalData[key] = 'all';
//       } else {
//         finalData[key] = filters[key];
//       }
//     });
//     const response = await AXIOS.post('/filter/getEvents', finalData).catch((err) => {
//       dispatch(setAlert({ message: getErrorMessage(err) }));
//       throw Error(err);
//     });
//     return response.data;
//   },
// );

// const getFilteredEventsThunkPending = (state) => {
//   state.eventsLoading = true;
// };

// const getFilteredEventsThunkFulfilled = (state, { payload }) => {
//   const { doctorCounts, events } = payload;
//   state.eventsLoading = false;
//   state.filters.events = events;
//   if (doctorCounts) {
//     state.filters.doctorCounts = doctorCounts;
//   }
//   if (state.filters.checkedEvents.length) {
//     const oldFilters = state.filters.checkedEvents;
//     const filteredEvents = events.filter(item => oldFilters.includes(item.id));
//     if (!filteredEvents.length) {
//       state.filters.checkedEvents = [];
//     } else {
//       state.filters.checkedEvents = filteredEvents.map(event => event.id);
//     }
//   }
// };

export const filteredEventsExtraReducers = (builder) => {
  builder
    .addCase(getFilterAreasThunk.pending, getFilterAreasThunkPending)
    .addCase(getFilterAreasThunk.fulfilled, getFilterAreasThunkFulfilled)
    .addCase(getFilterCasesThunk.pending, getFilterCasesThunkPending)
    .addCase(getFilterCasesThunk.fulfilled, getFilterCasesThunkFulfilled)
    .addCase(getFilterLanguagesThunk.pending, getFilterLanguagesThunkPending)
    .addCase(getFilterLanguagesThunk.fulfilled, getFilterLanguagesThunkFulfilled)
    .addCase(getFilterLocationsThunk.pending, getFilterLocationsThunkPending)
    .addCase(getFilterLocationsThunk.fulfilled, getFilterLocationsThunkFulfilled)
    .addCase(getFiltereOptionsThunk.pending, getFiltereOptionsThunkPending)
    .addCase(getFiltereOptionsThunk.fulfilled, getFiltereOptionsThunkFulfilled)
    .addCase(getFilteredEventsThunk.pending, getFilteredEventsThunkPending)
    .addCase(getFilteredEventsThunk.fulfilled, getFilteredEventsThunkFulfilled);
};
