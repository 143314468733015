import classNames from 'classnames';
import s from './Input.module.scss';

const Input = ({
  eye, eyeActive, onEyeClick, ...props
}) => (
  <div className={classNames(s.root, { [s.eyeRoot]: eye })}>
    <input {...props} className={s.input} />
    {eye && <div
      className={
      classNames(s.eye, { [s.eyeActive]: eyeActive })}
      onClick={onEyeClick} />}
  </div>
);

export default Input;
