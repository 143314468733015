import classNames from 'classnames';
import s from './Switcher.module.scss';

const Switcher = ({ className, active, ...props }) => (
  <div className={classNames(s.switch, { [s.active]: active, [className]: className })}>
    <span className={classNames(s.slider, s.round)} />
  </div>
);

export default Switcher;
