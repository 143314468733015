import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import NoItem from '../../../../components/NoItem/NoItem';
import { Button, Input, Select } from '../../../../components/UI';
import Modal from '../../../../components/UI/Modal/Modal';
import { EVENT_CONFIGS_KEYS } from '../../../../configs/events.configs';
import { modifySelectOptions } from '../../../../helpers/utils';
import {
  addDictionary, deleteDictionary, selectDictionaries, toggleDictionarySheet,
} from '../../../../redux/slices/settingsSlice';
import { createUpdateDictionaryThunk, deleteDictionaryThunk } from '../../../../redux/thunks/dictionarySettingsThunk';
import s from './DictionariesModal.module.scss';
import { setConfirm } from '../../../../redux/slices/confirmSlice';

const { LANGUAGE } = EVENT_CONFIGS_KEYS;

const DictionariesModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const eventConfigs = useSelector(state => state.userInfo.eventConfigs.configs);
  const dictionaries = useSelector(selectDictionaries);

  const languageOptions = useMemo(() => {
    const translations = dictionaries.map(item => item.name);
    return modifySelectOptions(eventConfigs[LANGUAGE].filter(lang => !translations.includes(lang)));
  }, [ eventConfigs[LANGUAGE], dictionaries ]);
  const [ languageSelect, setLanguageSelect ] = useState(null);

  const onAddClick = () => {
    dispatch(addDictionary(languageSelect?.value));
    setLanguageSelect(null);
  };

  const onItemClick = (item) => {
    dispatch(createUpdateDictionaryThunk(item));
  };

  const onDeleteClick = (item) => {
    if (item.new) {
      dispatch(deleteDictionary(item.name));
    } else {
      dispatch(setConfirm(() => {
        dispatch(deleteDictionaryThunk(item));
      }));
    }
  };

  return (
    <Modal onClose={closeModal} className={s.modal}>
      <div className={s.innerModal}>
        <div className={s.header}>
          <div>UI Languages</div>
        </div>
        <div className={s.createBoard}>
          <div>
            <Select
              isSearchable
              value={languageSelect}
              onChange={value => setLanguageSelect(value)}
              options={languageOptions}
              placeholder='Select Language' />
          </div>
          <div>
            <Button
              className={s.addButton}
              disabled={!languageSelect}
              onClick={onAddClick}>
              + Add Language
            </Button>
          </div>
        </div>
        <div className={s.content}>
          {dictionaries.length
            ? dictionaries.map((item, i) => (
              <div key={item.name} className={s.dictionaryItem}>
                <div className={s.dictionaryLable}>{item.name}</div>
                <div className={s.dictionaryInput}>
                  <Input
                    value={item.sheetURL}
                    onChange={({ target }) => dispatch(toggleDictionarySheet({ value: target.value, i }))}
                />
                </div>
                <div>
                  <Button
                    loading={item.loading}
                    onClick={() => onItemClick(item)}
                    disabled={!item.sheetURL}
                    className={s.linkButton}>
                    {!item.new ? 'Update' : 'Link'}
                  </Button>
                </div>
                <div>
                  <Button
                    variant='danger'
                    loading={item.loading}
                    onClick={() => onDeleteClick(item)}
                    className={classNames(s.linkButton, s.deleteButton)}>
                    Delete
                  </Button>
                </div>
              </div>
            ))
            : <NoItem title={'Translation'} />
        }
        </div>
        <div className={s.actions}>
          <Button
            onClick={closeModal}
            variant='light'>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DictionariesModal;
