import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { PAGE_COUNT } from '../configs/global.configs';

export const useInfiniteScroll = (fetch, resetDependency = [], reset = () => {}) => {
  const [ scrollLoading, setScrollLoading ] = useState(false);
  // const [ scrollError, setScrollError ] = useState(false);
  const [ hasMore, setHasMore ] = useState(true);
  const [ page, setPage ] = useState(1);
  const [ rerender, setRerender ] = useState(false);
  const [ restart, setRestart ] = useState(false);

  const restartFetch = () => setRestart(prev => !prev);

  useEffect(() => {
    let isMounted = true;
    // if(page === 0) {
    //     return
    // }
    if (page !== 1) {
      setScrollLoading(true);
    }
    // setScrollError(false);
    fetch(page).then((res) => {
      if (isMounted) {
        setHasMore(res?.payload?.length === PAGE_COUNT);
        setScrollLoading(false);
      }
      return res;
    });
    // .catch((e) => {
    //   setHasMore(false);
    //   console.error(e);
    // setScrollError(true);
    // });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [ page, rerender ])

  const pageObserver = useRef();
  const lastTicketRef = useCallback((node) => {
    if (scrollLoading) return;
    if (pageObserver.current) pageObserver.current.disconnect();
    pageObserver.current = new IntersectionObserver((e) => {
      if (e[0].isIntersecting && hasMore) {
        setPage(prev => prev + 1);
      }
    });
    if (node) {
      pageObserver.current.observe(node);
    }
  }, [ scrollLoading, hasMore ]);

  useEffect(() => () => {
    setPage(1);
    reset();
    setRerender(prev => !prev);
  }, [ ...resetDependency, restart ]);

  return {
    lastTicketRef,
    scrollLoading,
    restartFetch,
  };
};
