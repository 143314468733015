import eventsData from './slices/eventsSilce';
import patientsData from './slices/patientsSilce';
import patientsModal from './slices/patientsModalSlice';
import userInfo from './slices/userInfoSlice';
import alertData from './slices/alertSlice';
import confirmInfo from './slices/confirmSlice';
import access from './slices/accessSlice';
import filteredEvents from './slices/filteredEventsSlice';
import generalAnalys from './slices/generalAnalysSlice';
import questionAnalys from './slices/questionAnalysSilce';
import reportAnalys from './slices/reportAnalysSlice';
import satisfactionAnalys from './slices/satisfactionSlice';
import moderator from './slices/moderatorSlice';
import settings from './slices/settingsSlice';

export const reducers = {
  userInfo,
  eventsData,
  patientsData,
  patientsModal,
  alertData,
  confirmInfo,
  access,
  filteredEvents,
  generalAnalys,
  questionAnalys,
  reportAnalys,
  satisfactionAnalys,
  moderator,
  settings,
};
