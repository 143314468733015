import classNames from 'classnames';
import s from './Row.module.scss';

const Row = ({ children, className, ...props }) => (
  <div {...props} className={classNames(s.row, { [className]: className })}>
    <div>{children}</div>
  </div>
);

export default Row;
