import ContentLoader from 'react-content-loader';
import { Card } from '../../../../components/UI';
import s from './PatientItem.module.scss';

const PatientItemLoader = ({
  draft, check,
}) => (
  <div className={s.cardItem}>
    <Card>
      <ContentLoader
        speed={2}
        width='100%'
        height='4.3rem'
        backgroundColor='rgb(170, 170, 170)'
        foregroundColor='rgb(230, 230, 230)'
        >
        <rect x="1.8rem" y="0" rx="0.375rem" ry="0.375rem" width="1.5rem" height="1.3rem" />
        <rect x="10.2rem" y="0" rx="0.375rem" ry="0.375rem" width="21rem" height="1.3rem" />
        <rect x="10.2rem" y="2.2rem" rx="0.375rem" ry="0.375rem" width="17rem" height="1.3rem" />
        <rect x="4.7rem" y='0' rx="0.375rem" ry="0.375rem" width="3.75rem" height="100%" />

        {!draft
        && <rect x={check ? '90%' : '87%'} y='1.5rem' rx="0.375rem" ry="0.375rem" width={check ? '1.5rem' : '8%'} height="1.3rem" />}
        {!draft && !check && <rect x='82%' y='1.5rem' rx="0.375rem" ry="0.375rem" width='3%' height="1.3rem" />}
        {draft
    && <>
      <rect x="80%" y='1.5rem' rx="0.375rem" ry="0.375rem" width="2%" height="1.3rem" />
      <rect x="84%" y='1.5rem' rx="0.375rem" ry="0.375rem" width="4%" height="1.3rem" />
      <rect x="97%" y='1.5rem' rx="0.375rem" ry="0.375rem" width="1%" height="1.3rem" />
      <rect x="90%" y='1.5rem' rx="0.375rem" ry="0.375rem" width="5%" height="1.3rem" />
    </>}

      </ContentLoader>

    </Card>
  </div>
);

export default PatientItemLoader;
