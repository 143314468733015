import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetModerator } from '../redux/slices/moderatorSlice';
import { selectIsModerator, setIsConnected } from '../redux/slices/userInfoSlice';
import { getModeratorEventsThunk } from '../redux/thunks/moderatorThunk';
import { getUserConfigsThunk } from '../redux/thunks/userInfoThunk';

export const useSideEffects = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);

  useEffect(() => {
    const token = localStorage.getItem('token');
    token ? dispatch(getUserConfigsThunk(token)) : dispatch(setIsConnected());
  }, []);

  useEffect(() => {
    if (isModerator) {
      dispatch(getModeratorEventsThunk());
    } else {
      dispatch(resetModerator());
    }
  }, [ isModerator ]);
};
