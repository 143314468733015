import { useEffect } from 'react';

export const useOutsideClick = (ref, callback, isActive) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      isActive && callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ isActive ]);
};
