import { Input } from '../../../../../../components/UI';
import s from './EventExpectedParticipants.module.scss';

const EventExpectedParticipants = ({ value, toggleValue }) => {
  const changeHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if (num === '' || re.test(num)) {
      toggleValue(num);
    }
  };

  const plusHandler = () => {
    toggleValue((+value || 0) + 1);
  };

  const minusHandler = () => {
    if (+value && +value - 1 >= 0) {
      toggleValue(+value - 1);
    }
  };

  return (
    <div className={s.expectedRoot}>
      <div><Input value={value} onChange={changeHandler} type='tel' /></div>
      <div className={s.plusMinus}>
        <div onClick={plusHandler}>+</div>
        <div onClick={minusHandler}>-</div>
      </div>
    </div>
  );
};

export default EventExpectedParticipants;
