import { useSelector } from 'react-redux';
import GlobalLoader from './components/Loader/GlobalLoader';
import Wrapper from './components/Wrapper/Wrapper';
import { useSideEffects } from './hooks/useSideEffects';
import { selectIsConnected } from './redux/slices/userInfoSlice';

function App() {
  const isConnected = useSelector(selectIsConnected);

  useSideEffects();

  if (!isConnected) return <GlobalLoader />;
  return (
    <div><Wrapper /></div>
  );
}

export default App;
