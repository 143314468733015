import React from 'react';
import s from './Stars.module.scss';

const Stars = ({ review }) => <div>
  <div className={s.starsEmpty}>
    <div style={{ width: `${review}%` }} className={s.starsFull} />
  </div>
</div>;

export default Stars;
