import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from '../../AccessForms.module.scss';
import { Button } from '../../../../../../components/UI';
import { createAccessThunk } from '../../../../../../redux/thunks/accessThunk';
import { toggleCreateModal } from '../../../../../../redux/slices/accessSlice';

const AccessCreate = ({ isModerator }) => {
  const dispatch = useDispatch();
  const title = isModerator ? 'Event Moderator' : 'Event And Case Administrator';
  const { isCreateLoading } = useSelector(state => state.access);
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');

  const onClear = () => {
    setUsername('');
    setPassword('');
  };

  const onCancel = () => {
    setUsername('');
    setPassword('');
    dispatch(toggleCreateModal(false));
  };

  const onAddClick = () => {
    dispatch(createAccessThunk({ username, password, isModerator }))
      .unwrap().then(() => {
        onClear();
        dispatch(toggleCreateModal(false));
      });
  };

  const isAddDisabled = !username || !password;

  return (
    <div className={classNames(s.formItem, s.formItemCreate)}>
      <div className={s.formItemTitle}>Create New {title}</div>
      <div className={s.formItemMain}>
        <div className={s.formItemHolder}>
          <div className={s.formInputLabel}>Username</div>
          <div className={s.formInputWrapper}>
            <input value={username} onChange={e => setUsername(e.target.value)} />
          </div>
        </div>
        <div className={s.formItemHolder}>
          <div className={s.formInputLabel}>Password</div>
          <div className={s.formInputWrapper}>
            <input value={password} onChange={e => setPassword(e.target.value)} />
          </div>
        </div>
      </div>
      <div className={s.formActions}>
        <div className={s.formButton}>
          <Button
            variant='light'
            onClick={onClear}
            className={s.clearButton}>
            Clear
          </Button>
        </div>
        <div className={s.formButtonGroup}>
          <Button
            onClick={onCancel}
            variant='light'
            className={s.clearButton}>
            Cancel
          </Button>
          <Button
            disabled={isAddDisabled}
            onClick={onAddClick}
            loading={isCreateLoading}
            className={s.addButton}>
            Add New
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessCreate;
