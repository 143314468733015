import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Datepicker, Radio } from '../../../../../../components/UI';
import {
  accessDateChange, selectAccessFilterOptions,
  toggleAccessDateActivity,
} from '../../../../../../redux/slices/accessSlice';
import s from './DateSection.module.scss';

const DATE_SWITCHER_NAMES = [ 'No Date Range', 'Specific Date Range' ];

const DateSection = () => {
  const dispatch = useDispatch();
  const { isDateActive, startDate, endDate } = useSelector(selectAccessFilterOptions);

  const toggleDateActive = (value) => {
    dispatch(toggleAccessDateActivity(!!value));
  };

  const dateChange = (date, isStart) => {
    dispatch(accessDateChange({ date, isStart }));
  };

  return (
    <div className={s.dateBoard}>
      <div className={s.dateSwitcher}>
        {DATE_SWITCHER_NAMES.map((item, i) => (
          <div
            key={item}
            className={classNames(s.dateSwitchItem, {
              [s.activeDateState]: isDateActive === !!i,
            })}
            onClick={() => toggleDateActive(i)}
        >
            <div>{item}</div>
            <div><Radio active={isDateActive === !!i} size='22px' /></div>
          </div>
        ))}
      </div>
      <div className={classNames(s.datepickers, { [s.dissable]: !isDateActive })}>
        <div className={s.datepicker}>
          <div>
            <Datepicker
              selectsStart
              clear
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              popperPlacement="top"
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [ 0, -10 ],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    mainAxis: false,
                  },
                },
              ]}
              onChange={(date) => {
                dateChange(date, true);
                if (!endDate || (Date.parse(endDate) < Date.parse(date))) {
                  dateChange(date);
                }
              }}
          />
          </div>
          <div className={s.grayRow}><div>Date (on / from)</div></div>
        </div>
        <div className={s.datepicker}>
          <div>
            <Datepicker
              selectsEnd
              clear
              selected={endDate}
              minDate={startDate || Date.now()}
              startDate={startDate}
              endDate={endDate}
              popperPlacement="top"
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [ 0, -10 ],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    mainAxis: false,
                  },
                },
              ]}
              onChange={date => dateChange(date)}
          />
          </div>
          <div className={s.grayRow}>Date (till)</div>
        </div>
        {!isDateActive && <div className={s.datepickersBackground} />}
      </div>
    </div>
  );
};

export default DateSection;
