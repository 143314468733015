import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can from '../../components/Can/Can';
import Heading from '../../components/Heading/Heading';
import NoItem from '../../components/NoItem/NoItem';
import SortButton from '../../components/SortButton/SortButton';
import { CustomSelect } from '../../components/UI';
import { PATIENTS_SORT_OPTIONS } from '../../configs/patients.configs';
import { USER_ROLES_IDS } from '../../constants/ids';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { resetPatients } from '../../redux/slices/patientsSilce';
import { getPatientsThunk } from '../../redux/thunks/patientsDataThunk';
import PatientCasesModal from './components/PatientCasesModal/PatientCasesModal';
import PatientItem from './components/PatientItem/PatientItem';
import PatientItemLoader from './components/PatientItem/PatientItemLoader';
import PhotoPopup from '../../components/PhotoPopup/PhotoPopup';
import VideoPopup from '../../components/VideoPopup/VideoPopup';
import s from './PatientCases.module.scss';
import AddTranslationModal from './components/AddTranslationModal/AddTranslationModal';

const { SUPER_USER } = USER_ROLES_IDS;

const Draft = () => {
  const dispatch = useDispatch();
  const { patients, isLoading } = useSelector(state => state.patientsData);
  const [ activePatients, setActivePatients ] = useState([]);
  const [ modalInfo, setModalInfo ] = useState(null);
  const [ addTranslationModal, setAddTranslationModal ] = useState(null);
  const [ isDesc, setIsDesc ] = useState(false);
  const [ sortValue, setSortValue ] = useState(null);
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);
  const [ sortLabel, setSortLabel ] = useState('Default');

  const isSortDisabled = patients.length < 2;

  const { lastTicketRef, scrollLoading, restartFetch } = useInfiniteScroll(
    page => dispatch(getPatientsThunk({
      page, isDesc, sort: sortValue?.value,
    })),
    [ isDesc, sortValue ],
    () => dispatch(resetPatients()),
  );

  useEffect(() => () => dispatch(resetPatients()), []);

  const onEventClick = (id) => {
    if (activePatients.includes(id)) {
      return setActivePatients(activePatients.filter(item => item !== id));
    }
    return setActivePatients(prev => [ ...prev, id ]);
  };

  const changeSort = (value) => {
    setSortLabel(value.label);
    setSortValue(value);
  };

  return (
    <div className={s.casesRoot}>
      <Heading>
        <div className={s.headingActions}>
          <div className={s.headingActionInfo}>
            <div className={s.headingActionInfoItem}>
              <label>Sort :&nbsp;</label>
              <span>{sortLabel}</span>
              <span>{isDesc ? '(Descending)' : '(Ascending)'}</span>
            </div>
          </div>
          <div className={s.headingActionItem}>
            <div className={s.sortDropDownButton}>
              <CustomSelect
                isDisabled={isSortDisabled}
                value={sortValue}
                onChange={value => changeSort(value)}
                options={PATIENTS_SORT_OPTIONS} />
            </div>
            <SortButton
              disabled={isSortDisabled}
              desc={isDesc}
              onClick={() => !isSortDisabled && setIsDesc(prev => !prev)}
            />
            <Can roles={[ SUPER_USER ]}>
              <div className={s.headingItem}>
                <div onClick={() => setModalInfo({ type: 'Add' })} className={s.addPatientCaseHolder}>
                  <div className={s.addButton} />
                  <div className={s.addPatientCaseText}>Patient Case</div>
                </div>
              </div>
            </Can>
          </div>
        </div>
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className="cards">
              {(isLoading && !patients.length)
                ? Array(1).fill(null).map((_, idx) => (
                  <PatientItemLoader key={idx} draft />
                ))
                : patients.map((patient, i) => (
                  <PatientItem
                    isTranslations
                    restartFetch={restartFetch}
                    key={patient.id}
                    lastTicketRef={i === patients.length - 1 ? lastTicketRef : null}
                    draft
                    patient={patient}
                    setModalInfo={setModalInfo}
                    isActive={activePatients.includes(patient.id)}
                    openAddTranslation={() => setAddTranslationModal(patient)}
                    onEventClick={onEventClick} />))
            }
              {(!isLoading && !patients.length) && <NoItem title='Patient' />}
              {scrollLoading && <PatientItemLoader draft />}
            </div>
          </section>
        </section>
      </section>

      {/* modal */}
      {modalInfo && <PatientCasesModal
        type={modalInfo.type}
        patient={modalInfo.data}
        isView={modalInfo.isView}
        restartFetch={restartFetch}
        setShowPhoto={setShowPhoto}
        setShowVideo={setShowVideo}
        closeModal={() => setModalInfo(null)} />}
      {addTranslationModal && <AddTranslationModal
        patient={addTranslationModal}
        restartFetch={restartFetch}
        closeModal={() => setAddTranslationModal(null)} />}
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showVideo
        && <VideoPopup video={showVideo} onClose={() => setShowVideo(null)} />
      }
    </div>
  );
};

export default Draft;
