import ContentLoader from 'react-content-loader';
import { Card } from '../UI';

const FilterCardLoader = () => (
  <div>
    <Card>
      <ContentLoader
        speed={2}
        width='100%'
        height='4.5rem'
        backgroundColor='rgb(170, 170, 170)'
        foregroundColor='rgb(230, 230, 230)'
        >
        <rect x="1.8rem" y="0" rx="0.375rem" ry="0.375rem" width="1.3rem" height="1.3rem" />
        <rect x="4.7rem" y="0" rx="0.375rem" ry="0.375rem" width="6rem" height="1.3rem" />
        <rect x="21.2rem" y="0" rx="0.375rem" ry="0.375rem" width="3rem" height="1.3rem" />
        <rect x="4.7rem" y="2.2rem" rx="0.375rem" ry="0.375rem" width="5rem" height="1.3rem" />
        <rect x="21.2rem" y="2.2rem" rx="0.375rem" ry="0.375rem" width="35%" height="1.3rem" />
        <rect x="92.5%" y="0" rx="0.375rem" ry="0.375rem" width="3.875rem" height="3.33rem" />

      </ContentLoader>
    </Card>
  </div>
);

export default FilterCardLoader;
