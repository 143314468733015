import classNames from 'classnames';
import s from './Radio.module.scss';

const Radio = ({
  active, size = '42px', disabled, ...props
}) => (
  <div
    {...props}
    className={classNames(s.radioOuter, { [s.disabled]: disabled })}
    style={{ width: size, height: size }}>
    <div className={classNames(s.radioInner, { [s.active]: active })} />
  </div>
);

export default Radio;
