import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setFormatedLocations } from '../redux/slices/filteredEventsSlice';

export const useFormatLocations = (allLocations, isLocal) => {
  const dispatch = useDispatch();

  const [ allRegions, allCountries, allCities ] = useMemo(() => {
    const regions = {
      items: [],
      count: 0,
      checkCount: 0,
    };
    const countries = {
      items: [],
      count: 0,
      checkCount: 0,
      visibleCount: 0,
    };
    const cities = {
      items: [],
      count: 0,
      checkCount: 0,
      visibleCount: 0,
    };
    allLocations.forEach((regionItem, regionIndex) => {
      regions.items.push({
        name: regionItem.region,
        index: regionIndex,
        unchecked: regionItem.unchecked,
      });
      regions.count += 1;
      if (!regionItem.unchecked) {
        regions.checkCount += 1;
      }
      if (regionItem.country) {
        regionItem.country.forEach((countryItem, countryIndex) => {
          const isCountryInvisible = regionItem.unchecked;
          countries.items.push({
            name: countryItem.country,
            index: countryIndex,
            unchecked: countryItem.unchecked,
            invisible: isCountryInvisible,
            regionIndex,
          });
          countries.count += 1;
          if (!countryItem.unchecked && !isCountryInvisible) {
            countries.checkCount += 1;
          }
          if (!isCountryInvisible) {
            countries.visibleCount += 1;
          }
          if (countryItem.city) {
            countryItem.city.forEach((cityItem, cityIndex) => {
              if (cityItem.city) {
                const isCityInvisible = countryItem.unchecked || regionItem.unchecked;
                cities.items.push({
                  name: cityItem.city,
                  index: cityIndex,
                  unchecked: cityItem.unchecked,
                  invisible: isCityInvisible,
                  regionIndex,
                  countryIndex,
                });
                cities.count += 1;
                if (!cityItem.unchecked && !isCityInvisible) {
                  cities.checkCount += 1;
                }
                if (!isCityInvisible) {
                  cities.visibleCount += 1;
                }
              }
            });
          }
        });
      }
    });
    return [ regions, countries, cities ];
  }, [ allLocations ]);

  useEffect(() => {
    if (!isLocal) {
      dispatch(setFormatedLocations({ allRegions, allCountries, allCities }));
    }
  }, [ allRegions, allCountries, allCities, isLocal ]);

  return { allRegions, allCities, allCountries };
};
