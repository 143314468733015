import classNames from 'classnames';
import { Card } from '../../../../components/UI';
import { PATIENT_BASE_INFO_KEYS } from '../../../../configs/patients.configs';
import { GENDER_NAMES } from '../../../../constants/names';
import { getPatientDefaultImage } from '../../../../helpers/utils';
import s from './PatientItem.module.scss';

const {
  GENDER, AGE, FIRST_NAME, HISTORY, LAST_NAME, SYMPTOMS, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const PatientItemShort = ({
  patient, onEventClick, isActive, check, onCheck, checked,
}) => {
  const baseInfo = patient.baseInfo || patient.activeTranslation.baseInfo || {};

  const patientImage = patient.avatar
    ? process.env.REACT_APP_IMAGE_URL + patient.avatar
    : getPatientDefaultImage(baseInfo[GENDER]);

  return (
    <div className={s.cardItemShort}>
      <Card
        closable
        short
        checked={checked}
        check={check}
        onCheck={onCheck}
        active={isActive}
        actions={<div className={s.actionsShort} />}
        onClick={() => onEventClick(patient.id)}
        >
        <div className={s.contentWrapper}>
          <div className={classNames(s.imageWrapperShort,
            { [s.imageWrapperActiveShort]: isActive })}>
            <img src={patientImage} alt={baseInfo[LAST_NAME]} />
          </div>
          <div className={s.contentShort}>
            <div className={s.bold}>
              {`${baseInfo[GENDER]} ${baseInfo[FIRST_NAME]}${baseInfo[LAST_NAME]
                ? ` ${baseInfo[LAST_NAME]}` : ''}, ${baseInfo[AGE]} - ${baseInfo[VISIT]} Visit`}
            </div>
            <div>{GENDER_NAMES[baseInfo[GENDER]]}{baseInfo[AGE] && `, ${baseInfo[AGE]}`}</div>
            {isActive
            && baseInfo[SYMPTOMS] && Array.isArray(baseInfo[SYMPTOMS])
             && <div>
               {baseInfo[SYMPTOMS].map((symptom, i) => <span key={i}>{symptom} </span>)}
             </div>
            }
            {isActive && baseInfo[HISTORY] && Array.isArray(baseInfo[HISTORY])
            && <div>
              {baseInfo[HISTORY].map((diagnos, i) => <span key={i}>{diagnos} </span>)}
              </div>
            }
          </div>
        </div>

      </Card>
    </div>
  );
};

export default PatientItemShort;
