import AccessCreate from '../../../pages/Access/components/AccessForms/components/AccessCreate/AccessCreate';
import s from './CreateUserModal.module.scss';

const CreateUserModal = ({ isModerator }) => (
  <div className={s.background}>
    <div className={s.modal}>
      <AccessCreate isModerator={isModerator} />
    </div>
  </div>
);

export default CreateUserModal;
