import classNames from 'classnames';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import s from './Datepicker.module.scss';

const Datepicker = (props) => {
  const ExampleCustomInput = forwardRef(({
    value = '', onClick, clear, onClear,
  }, ref) => (
    <div className={s.root}>
      <div
        className={classNames(
          s.input, { [s.disabled]: props.disabled },
        )}
        ref={ref}
        onClick={onClick}>
        <div className={s.date}>{value}</div>
        <div className={s.calendarIcon} />
      </div>

      {value && clear && <div onClick={onClear} className={s.clear} />}
    </div>
  ));
  return (
    <DatePicker
      dateFormat="MMM d, yyyy"
      customInput={<ExampleCustomInput clear={props.clear} onClear={() => props.onChange(null)} />}
      placeholderText='Choose date...'
      showYearDropdown
      scrollableYearDropdown
      closeOnScroll
      {...props}
    />
  );
};

export default Datepicker;
