import classNames from 'classnames';
import s from './Modal.module.scss';

const Modal = ({
  title = '',
  children,
  actions,
  className,
  onClose = () => { },
}) => (
  <>
    <div onClick={onClose} className={s.background} />
    <div className={classNames(s.modal, { [s.noTitleModal]: !title, [className]: className })}>
      <section className={s.modalContainer}>
        {title && (
          <div className={s.header}>
            <div className={s.title}>{title}</div>
          </div>
        )}
        <div className={classNames(s.content, { [s.noTitleContent]: !title })}>
          <div className={s.contentBody}>{children}</div>
          {actions && <div className={s.actions}>{actions}</div>}
        </div>
      </section>
    </div>
  </>
);

export default Modal;
