import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './PatientsModal.module.scss';
import { Button, Modal } from '../UI';
import PatientItem from '../../pages/PatientCases/components/PatientItem/PatientItem';
import PatientItemLoader from '../../pages/PatientCases/components/PatientItem/PatientItemLoader';
import NoItem from '../NoItem/NoItem';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { resetPatientsModal } from '../../redux/slices/patientsModalSlice';

const PatientsModal = ({
  closeModal, patientCases, onOkClick, fetchCases, radio, ignorCases = [],
}) => {
  const dispatch = useDispatch();
  const { patients, isLoading } = useSelector(state => state.patientsModal);
  const [ activePatients, setActivePatients ] = useState([]);
  const [ checkedPatients, setCheckedPatients ] = useState(patientCases);

  const { lastTicketRef, scrollLoading } = useInfiniteScroll(
    page => fetchCases(page),
  );

  useEffect(() => () => dispatch(resetPatientsModal()), []);

  const onEventClick = (id) => {
    if (activePatients.includes(id)) {
      return setActivePatients(activePatients.filter(item => item !== id));
    }
    return setActivePatients(prev => [ ...prev, id ]);
  };

  const onPatientChecked = (patient) => {
    if (checkedPatients.find(item => item.id === patient.id)) {
      return setCheckedPatients(checkedPatients.filter(el => el.id !== patient.id));
    }
    return setCheckedPatients(prev => (radio ? [ patient ] : [ ...prev, patient ]));
  };

  return (
    <Modal className={s.modal}>
      <div className={s.innerModal}>
        <div className={s.header}>
          <div>Add Patient Case</div>
        </div>
        <div className={s.content}>
          {isLoading
            ? Array(1).fill(null).map((_, idx) => (
              <PatientItemLoader key={idx} check />
            ))
            : patients.map((patient, i) => {
              if (ignorCases.includes(patient.id)
              || (patient?.previous && ignorCases.includes(patient.previous.id))) return null;
              return (
                <PatientItem
                  check
                  checked={!!checkedPatients.find(item => item.id === patient.id)}
                  onCheck={() => onPatientChecked(patient)}
                  key={patient.id}
                  lastTicketRef={i === patients.length - 1 ? lastTicketRef : null}
                  patient={patient}
                  isActive={activePatients.includes(patient.id)}
                  onEventClick={() => onEventClick(patient.id)}
                />);
            })
      }
          {(!isLoading && !patients.length) && <NoItem title='Patient' />}
          {scrollLoading && <PatientItemLoader />}
        </div>
        <div className={s.actions}>
          <Button onClick={closeModal} variant='light'>Cancel</Button>
          <Button onClick={() => onOkClick(checkedPatients)}>OK</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PatientsModal;
