import { Progress } from '../../../../../components/UI';
import s from './SatisfactionItem.module.scss';
import Stars from '../../../../../components/Starts/Stars';

const keys = [ 5, 4, 3, 2, 1 ];

const SatisfactionItem = ({
  title, rate, percentage, doctors,
}) => (
  <div className={s.satisfactionItem}>
    <div className={s.title}>{title}</div>
    <div className={s.starsBoard}>
      <Stars review={(rate * 100 / 5)} />
      <div className={s.starInfo}>
        <div><span className={s.bold}>{rate}</span> Out of <span className={s.bold}>5</span></div>
        <div>({doctors} Doctors)</div>
      </div>
    </div>
    <div className={s.progressBoard}>
      {keys.map(item => (
        <div key={item} className={s.progressRow}>
          <div>{item} Star</div>
          <div><Progress percent={percentage[item]} /></div>
          <div>{percentage[item]}%</div>
        </div>
      ))}
    </div>
  </div>
);

export default SatisfactionItem;
