import { useEffect, useState } from 'react';

export const useSideResize = (
  ref, toggleRef, startPercent = 50, isTopBottom, MIN = 35, MAX = 65,
) => {
  const [ isMouseDown, setIsMouseDown ] = useState(false);

  const [ percent, setPercent ] = useState(startPercent);

  const setPosition = (e) => {
    const {
      x, width, y, height,
    } = ref.current.getBoundingClientRect();
    const result = isTopBottom ? ((e.clientY - y) / height * 100) : ((e.clientX - x) / width * 100);

    if (result < MIN) return setPercent(MIN);
    if (result > MAX) return setPercent(MAX);
    return setPercent(result);
  };

  const onMouseUp = () => setIsMouseDown(false);

  useEffect(() => {
    if (isMouseDown) {
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', setPosition);
      document.body.style.cursor = 'grabbing';
      if (toggleRef.current) {
        toggleRef.current.style.cursor = 'grabbing';
      }
    }

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', setPosition);
      document.body.style.cursor = 'default';
      if (toggleRef.current) {
        toggleRef.current.style.cursor = 'grab';
      }
    };
  }, [ isMouseDown ]);

  return [ percent, setIsMouseDown ];
};
