import { createSlice } from '@reduxjs/toolkit';
import { USER_ROLES_IDS } from '../../constants/ids';
import { userInfoExtraReducers } from '../thunks/userInfoThunk';

const initialState = {
  isConnected: false,
  isAuth: false,
  role: null,
  token: null,
  isAuthLoading: false,
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (_, { payload }) => ({ isAuth: true, ...payload }),
    resetUser: () => ({ ...initialState, isConnected: true }),
    setIsConnected: (state) => { state.isConnected = true; },
    updateConfigs: (state, { payload }) => { state.eventConfigs = payload; },
  },
  extraReducers: userInfoExtraReducers,
});

// ACTIONS
export const {
  setUserInfo, resetUser, setIsConnected, updateConfigs,
} = userInfoSlice.actions;

// SELECTORS
export const selectIsAuth = state => state.userInfo.isAuth;
export const selectRole = state => state.userInfo.role;
export const selectIsModerator = state => state.userInfo.role === USER_ROLES_IDS.EVENT_MODERATOR;
export const selectIsConnected = state => state.userInfo.isConnected;

export default userInfoSlice.reducer;
