import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { ORDER_TYPES } from '../../constants/names';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

const { DESC, ASC } = ORDER_TYPES;

// GET PATIENTS
export const getPatientsForModalThunk = createAsyncThunk(
  'patientsModal/getPatientsForModal',
  async ({
    isApproved, isDesc, page = 1, pageCount = 20, sort,
  }, { dispatch }) => {
    const approved = isApproved ? 1 : 0;
    const order = isDesc ? DESC : ASC;
    let url = `/case/list?isApproved=${approved}&order=${order}&page=${page}&pageCount=${pageCount}`;
    if (sort) {
      url = `${url}&sort=${sort}`;
    }
    const response = await AXIOS.get(url).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getPatientsForModalThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getPatientsForModalThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  if (payload && Array.isArray(payload)) {
    payload.forEach((patient) => {
      if (patient.translations?.length) {
        // eslint-disable-next-line prefer-destructuring
        patient.activeTranslation = patient.translations[0];
        // patient.languages = patient.translations.map(el => el.language);
      }
    });
    state.patients = state.patients.concat(payload);
  }
};

const getPatientsForModalThunkRejected = (state, { error }) => {
  state.isLoading = false;
  // state.error = getErrorMessage(error);
};

export const patientsModalExtraReducers = (builder) => {
  builder
    .addCase(getPatientsForModalThunk.pending, getPatientsForModalThunkPending)
    .addCase(getPatientsForModalThunk.fulfilled, getPatientsForModalThunkFulfilled)
    .addCase(getPatientsForModalThunk.rejected, getPatientsForModalThunkRejected);
};
