import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useSideResize } from '../../hooks/useSideResize';
import s from './FiltersModal.module.scss';
import FilterLeftSection from './components/FilterLeftSection/FilterLeftSection';
import FilterLocationSection from './components/FilterLocationSection/FilterLocationSection';
import FilterDateSection from './components/FilterDateSection/FilterDateSection';
import { updateFilters } from '../../redux/slices/filteredEventsSlice';
import FiltersModalActions from './components/FiltersModalActions/FiltersModalActions';
import { useFormatLocations } from '../../hooks/useFormatLocations';
import { EVENT_KEYS } from '../../constants/names';

const { LOCATIONS } = EVENT_KEYS;

const FiltersModal = ({ caseMulti }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => state.filteredEvents.isOpen);
  const allLocations = useSelector(state => state.filteredEvents.allItems[LOCATIONS]);

  const contentRef = useRef(null);
  const middleToggleRef = useRef(null);

  const onItemClick = (name, value, isAll, isRadio) => {
    dispatch(updateFilters({
      name, value, isAll, isRadio,
    }));
  };

  const [ middleWidth, setIsMouseDownMiddle ] = useSideResize(contentRef, middleToggleRef, 55);

  useFormatLocations(allLocations);

  return (
    <div className={classNames(s.background, { [s.hidden]: !isModalOpen })}>
      <section className={s.modalContainer}>
        <div className={s.modal}>
          <div className={s.content} ref={contentRef}>
            <div className={s.leftSection} style={{ width: `${middleWidth}%` }}>
              <FilterLeftSection caseMulti={caseMulti} onItemClick={onItemClick} />
            </div>
            <div
              ref={middleToggleRef}
              onMouseDown={() => setIsMouseDownMiddle(true)}
              className={s.leftRightToggle}>
              <div className={s.leftRightToggle__inner} />
            </div>
            <div className={s.rightSection}>
              <FilterLocationSection onItemClick={onItemClick} />
              <FilterDateSection onItemClick={onItemClick} />
            </div>
          </div>
          <FiltersModalActions />
        </div>
      </section>
    </div>
  );
};

export default FiltersModal;
