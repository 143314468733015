import classNames from 'classnames';
import { Card } from '../UI';
import s from './NoItem.module.scss';

const NoItem = ({
  title, short, analys, children,
}) => (
  <Card noItem>
    <div className={classNames(s.noItem, { [s.short]: short, [s.analys]: analys })}>
      {children && <p>{children}</p>}
      {!children && (analys ? <p>No selected event, case or doctor</p> : <p>No {title} Item</p>)}
    </div>
  </Card>
);

export default NoItem;
