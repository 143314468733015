import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { EVENT_TYPE_IDS } from '../../../../constants/ids';
import { EVENT_KEYS, EVENT_TYPE_NAMES } from '../../../../constants/names';
import {
  toggleCity, toggleCountry, toggleFilterEventTypes, toggleRegion,
} from '../../../../redux/slices/filteredEventsSlice';
import { CheckboxPure } from '../../../UI';
import FilterCity from './components/FilterCity/FilterCity';
import FilterCountry from './components/FilterCountry/FilterCountry';
import FilterRegion from './components/FilterRegion/FilterRegion';
import s from './FilterLocationSection.module.scss';

const { EVENT_TYPE } = EVENT_KEYS;

const eventTypes = Object.values(EVENT_TYPE_IDS);

const FilterLocationSection = () => {
  const dispatch = useDispatch();
  const activeEventTypes = useSelector(state => state.filteredEvents.filters[EVENT_TYPE]);
  const isLocationsLoading = useSelector(state => state.filteredEvents.isLocationsLoading);
  const { allRegions, allCities, allCountries } = useSelector(state => state.filteredEvents.formatedLocations);

  const onTypeClick = (value) => {
    dispatch(toggleFilterEventTypes(value));
  };

  const onRegionItemClick = (data) => {
    dispatch(toggleRegion(data));
  };

  const onCountryItemClick = (data) => {
    dispatch(toggleCountry(data));
  };

  const onCityItemClick = (data) => {
    dispatch(toggleCity(data));
  };

  return (
    <div className={s.section}>
      <div className={s.listBoard}>
        <div>
          <FilterRegion
            allRegions={allRegions}
            isLoading={isLocationsLoading}
            onItemClick={onRegionItemClick} />
        </div>

        <div>
          <FilterCountry
            allCountries={allCountries}
            isLoading={isLocationsLoading}
            onItemClick={onCountryItemClick} />
        </div>
        <div>
          <FilterCity
            allCities={allCities}
            isLoading={isLocationsLoading}
            onItemClick={onCityItemClick} />
        </div>
      </div>
      <div className={s.eventBoard}>
        <div>Event Type</div>
        <div className={s.eventTypes}>
          {eventTypes.map((item) => {
            const isTypeDisabled = activeEventTypes.length === 1 && activeEventTypes[0] === item;
            return (
              <div
                onClick={() => !isTypeDisabled && onTypeClick(item)}
                className={classNames(s.eventType, { [s.disabled]: isTypeDisabled })}
                key={item}>
                <div>{EVENT_TYPE_NAMES[item]}</div>
                <div className={s.typeCheckbox}>
                  <CheckboxPure disabled={isTypeDisabled} checked={activeEventTypes.includes(item)} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterLocationSection;
