import React from 'react';
import s from './ViewRow.module.scss';

const ViewRow = ({ children }) => (
  <div className={s.viewRow}>
    <div>
      {children}
    </div>
  </div>
);

export default ViewRow;
