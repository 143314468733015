import { useSelector } from 'react-redux';
import EventItem from '../../../../Events/components/EventItem/EventItem';
import s from './PastAnalysisList.module.scss';

const PastAnalysisList = () => {
  const { events } = useSelector(state => state.filteredEvents.eventsData);

  return (
    <div className={s.pastAnalysis}>
      {events.map(event => (
        <EventItem key={event.id} isActive event={event} closable={false} select open />
      ))}
    </div>
  );
};

export default PastAnalysisList;
