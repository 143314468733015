import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';
import { updateConfigs } from '../slices/userInfoSlice';

// GET IMAGES
export const getImagesThunk = createAsyncThunk(
  'settings/getImages',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/image/list').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getImagesThunkPending = (state) => {
  state.isImagesLoading = true;
};

const getImagesThunkFulfilled = (state, { payload }) => {
  state.isImagesLoading = false;
  state.images = payload;
};

const getImagesThunkRejected = (state) => {
  state.isImagesLoading = false;
};

// ADD IMAGE

export const addImageThunk = createAsyncThunk(
  'settings/addImage',
  async ({ type, image }, { dispatch }) => {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('image', image);
    const response = await AXIOS.post('/image/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getImagesThunk());
    return response.data;
  },
);

export const deleteImageThunk = createAsyncThunk(
  'settings/deleteImage',
  async ({ id }, { dispatch }) => {
    const response = await AXIOS.delete(`/image/delete?id=${id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getImagesThunk());
    return response.data;
  },
);

// GET EVENT CONFIGS
export const updateEventLinkThunk = createAsyncThunk(
  'settings/setEventLink',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/event/configs', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(updateConfigs(response.data));
    dispatch(setAlert({ message: 'SUCCESS', type: 'success' }));
    return response.data;
  },
);

const updateEventLinkThunkPending = (state) => {
  state.isConfigsLoading = true;
};

const updateEventLinkThunkFuldilled = (state, { payload }) => {
  state.isConfigsLoading = false;
};

const updateEventLinkThunkRejected = (state) => {
  state.isConfigsLoading = false;
};

export const settingsExtraReducers = (builder) => {
  builder
    .addCase(getImagesThunk.pending, getImagesThunkPending)
    .addCase(getImagesThunk.fulfilled, getImagesThunkFulfilled)
    .addCase(getImagesThunk.rejected, getImagesThunkRejected)
    .addCase(updateEventLinkThunk.pending, updateEventLinkThunkPending)
    .addCase(updateEventLinkThunk.fulfilled, updateEventLinkThunkFuldilled)
    .addCase(updateEventLinkThunk.rejected, updateEventLinkThunkRejected);
};
