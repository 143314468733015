import React from 'react';
import classNames from 'classnames';
import s from './Table.module.scss';

const Table = ({ children, className, ...props }) => (
  <div {...props} className={classNames(s.table, { [className]: className })} >
    {children}
  </div>
);

Table.Header = ({ children, className }) => (
  <div className={classNames(s.table__header, { [className]: className })}>{children}</div>
);

Table.HeaderCell = ({
  children, width, length, className,
}) => (
  <div
    className={classNames(s.table__headerCell, { [className]: className })}
    style={{ width: width || `calc(100% / ${length})` }}>
    {children}
  </div>
);

Table.Body = ({ children, className }) => (
  <div className={classNames(s.table__body, { [className]: className })}>{children}</div>
);

Table.Row = ({ children, className }) => (
  <div className={classNames(s.table__row, { [className]: className })}>{children}</div>
);

Table.Cell = ({
  children, width, length, className, ...props
}) => (
  <div
    className={classNames(s.table__cell, { [className]: className })}
    style={{ width: width || `calc(100% / ${length})` }}
    {...props}>
    {children}
  </div>
);

export default Table;
