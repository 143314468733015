import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET MODERATOR EVENTS
export const getModeratorEventsThunk = createAsyncThunk(
  'moderator/getEventsByModerator',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('event/getEventsByModerator').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getModeratorEventsThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getModeratorEventsThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.events = payload;
  if (!state.activeEvent && payload.length) {
    state.activeEvent = payload[0];
  }
};

const getModeratorEventsThunkRejected = (state, { error }) => {
  state.isLoading = false;
  state.error = getErrorMessage(error);
};

export const moderatorExtraReducers = (builder) => {
  builder
    .addCase(getModeratorEventsThunk.pending, getModeratorEventsThunkPending)
    .addCase(getModeratorEventsThunk.fulfilled, getModeratorEventsThunkFulfilled)
    .addCase(getModeratorEventsThunk.rejected, getModeratorEventsThunkRejected);
};
