import { createSlice } from '@reduxjs/toolkit';
import { generalAnalysExtraReducers } from '../thunks/generalAnalysThunk';

const initialState = {
  analys: {
    events: null,
    cases: null,
    treatments: null,
    doctors: null,
    total: null,
    started: null,
    completed: null,
    abandoned: null,
  },
  isLoading: false,
};

export const generalAnalysSlice = createSlice({
  name: 'generalAnalys',
  initialState,
  reducers: {
    addGeneralAnalys: (state, { payload }) => { state.analys = payload; },
    resetGeneralAnalys: () => initialState,
  },
  extraReducers: generalAnalysExtraReducers,
});

// ACTIONS
export const { addGeneralAnalys, resetGeneralAnalys } = generalAnalysSlice.actions;

// SELECTORS
export const selectGeneralAnalys = state => state.generalAnalys.events;

export default generalAnalysSlice.reducer;
