import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// SIGN IN
export const loginUserThunk = createAsyncThunk(
  'userInfo/loginUser',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/user/auth', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const loginUserThunkPending = (state) => {
  state.isAuthLoading = true;
};

const loginUserThunkFulfilled = (_, { payload }) => {
  const { token } = payload;
  if (token) {
    localStorage.setItem('token', token);
    AXIOS.defaults.headers.common['x-access-token'] = token;
  }
  return {
    isConnected: true,
    isAuth: true,
    isAuthLoading: false,
    ...payload,
  };
};

const loginUserThunkRejected = (state, { error }) => {
  state.isAuthLoading = false;
  // state.error = getErrorMessage(error);
};

// GET USER CONFIGS BY TOKEN
export const getUserConfigsThunk = createAsyncThunk(
  'userInfo/getUserConfigs',
  async (token) => {
    const headers = token ? { headers: { 'x-access-token': token }} : {};
    const response = await AXIOS.get('/user/get', headers);
    return { ...response.data, token };
  },
);

const getUserConfigsThunkRejected = (state) => {
  localStorage.removeItem('token');
  state.isConnected = true;
};

export const userInfoExtraReducers = (builder) => {
  builder
    .addCase(loginUserThunk.pending, loginUserThunkPending)
    .addCase(loginUserThunk.fulfilled, loginUserThunkFulfilled)
    .addCase(loginUserThunk.rejected, loginUserThunkRejected)
    .addCase(getUserConfigsThunk.fulfilled, loginUserThunkFulfilled)
    .addCase(getUserConfigsThunk.rejected, getUserConfigsThunkRejected);
};
