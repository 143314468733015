import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { ORDER_TYPES } from '../../constants/names';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

const { DESC, ASC } = ORDER_TYPES;

// GET PATIENTS
export const getPatientsThunk = createAsyncThunk(
  'patientsData/getPatients',
  async ({
    isApproved, isDesc, page = 1, pageCount = 20, sort,
  }, { dispatch }) => {
    const approved = isApproved ? 1 : 0;
    const order = isDesc ? DESC : ASC;
    let url = `/case/list?isApproved=${approved}&order=${order}&page=${page}&pageCount=${pageCount}`;
    if (sort) {
      url = `${url}&sort=${sort}`;
    }
    const response = await AXIOS.get(url).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getPatientsThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getPatientsThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  if (payload && Array.isArray(payload)) {
    payload.forEach((patient) => {
      if (patient.translations?.length) {
        // eslint-disable-next-line prefer-destructuring
        patient.activeTranslation = patient.translations[0];
        // patient.languages = patient.translations.map(el => el.language);
      }
    });
    state.patients = state.patients.concat(payload);
  }
};

const getPatientsThunkRejected = (state, { error }) => {
  state.isLoading = false;
  // state.error = getErrorMessage(error);
};

// GET EVENT PATIENTS

export const getEventPatientsThunk = createAsyncThunk(
  'patientsData/getEventPatients',
  async ({
    language, area, page, pageCount = 20,
  }, { dispatch }) => {
    const response = await AXIOS.post('/case/getByLanguage', {
      language,
      area,
      page,
      pageCount,
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getEventPatientsThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  const { cases, count } = payload;
  state.patients = state.patients.concat(cases);
  state.count = count;
};

// IMPORT PATIENT
export const importPatientThunk = createAsyncThunk(
  'patientsData/importPatient',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/case/importCaseTranslation', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const importPatientThunkPending = (state) => {
  state.isImportLoading = true;
};

const importPatientThunkFulfilled = (state) => {
  state.isImportLoading = false;
};

const importPatientThunkRejected = (state) => {
  state.isImportLoading = false;
};

// CREATE OR MODIFY PATIENT
export const createModifyPatientThunk = createAsyncThunk(
  'patientsData/createModifyPatient',
  async ({
    isModify, imgFile, avatar, sheetContent, closeModal, restartFetch,
    files, videos, addFiles, addVideoFiles, importedCases, sheet, language, ...data
  }, { dispatch }) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (data[item]) {
        formData.append(item, data[item]);
      }
    });
    if (imgFile || avatar) {
      formData.append('avatar', imgFile || avatar);
    }
    if (addFiles.length) {
      addFiles.forEach((file) => {
        formData.append('files', file);
      });
    }
    if (addVideoFiles.length) {
      addVideoFiles.forEach((video) => {
        formData.append('videos', video);
      });
    }

    if (files?.length) {
      files.forEach((file) => {
        formData.append('files', JSON.stringify(file));
      });
    }

    if (videos?.length) {
      videos.forEach((video) => {
        formData.append('videos', JSON.stringify(video));
      });
    }

    if (!isModify) {
      formData.append('sheet', sheet);
      formData.append('language', language);
    } else if (importedCases.length) {
      formData.append('translations', JSON.stringify(importedCases));
    }

    const response = await AXIOS.post(`/case/${isModify ? 'update' : 'create'}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal();
    return response.data;
  },
);

const createModifyPatientThunkPending = (state) => {
  state.isCreateLoading = true;
};

const createModifyPatientThunkFulfilled = (state) => {
  state.isCreateLoading = false;
};

const createModifyPatientThunkRejected = (state) => {
  state.isCreateLoading = false;
};

// ADD TRANSLATION
export const addTranslationThunk = createAsyncThunk(
  'patientsData/addTranslation',
  async ({
    id, language, sheetURL, closeModal, restartFetch,
  }, { dispatch }) => {
    const response = await AXIOS.post('/case/addCaseTranslation', { id, translations: [{ language, sheetURL }]}).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal();
    return response.data;
  },
);

// DELETE PATIENT TRANSLATION
export const deleteTranslationThunk = createAsyncThunk(
  'patientsData/deleteTranslation',
  async ({
    id, restartFetch,
  }, { dispatch }) => {
    const response = await AXIOS.delete(`/case/deleteCaseTranslation?id=${id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    return response.data;
  },
);

// DRAFT OR APPROVED PATIENT
export const draftApprovedPatientThunk = createAsyncThunk(
  'patientsData/draftApprovedPatient',
  async ({ isApproved, id, restartFetch }, { dispatch }) => {
    const approved = isApproved ? 1 : 0;
    const response = await AXIOS.post('/case/approve', { id, status: approved }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    return response.data;
  },
);

// DELETE PATIENT
export const deletePatientThunk = createAsyncThunk(
  'patientsData/deletePatient',
  async ({ id, restartFetch, closeModal }, { dispatch }) => {
    const response = await AXIOS.delete(`/case/delete?id=${id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal();
    return response;
  },
);

const deletePatientThunkPending = (state) => {
  state.isDeleteLoading = true;
};

const deletePatientThunkFulfilled = (state) => {
  state.isDeleteLoading = false;
};

const deletePatientThunkRejected = (state) => {
  state.isDeleteLoading = false;
};

// SWITCH PATIENT SHAREABLE
export const switchPatientShareThunk = createAsyncThunk(
  'patientsData/switchPatientShare',
  async (data, { dispatch }) => {
    await AXIOS.post('/case/shareable ', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return data;
  },
);

const switchPatientShareThunkFulfilled = (state, { payload }) => {
  const { id, status } = payload;
  const currentPatientIndex = state.patients.findIndex(patient => patient.id === id);
  if (currentPatientIndex !== -1) {
    state.patients[currentPatientIndex].shareable = status;
  }
};

// GET PATIENT VIEW ID
export const getPatientViewIdThunk = createAsyncThunk(
  'patientsData/getPatientViewId',
  async ({ id, language }, { dispatch }) => {
    const response = await AXIOS.get(`/case/admin/view?id=${id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    window.open(`${process.env.REACT_APP_FRONT_URL}case=${response.data.id}&language=${language}/`, '_blank').focus();
  },
);

export const patientsDataExtraReducers = (builder) => {
  builder
    .addCase(getPatientsThunk.pending, getPatientsThunkPending)
    .addCase(getPatientsThunk.fulfilled, getPatientsThunkFulfilled)
    .addCase(getPatientsThunk.rejected, getPatientsThunkRejected)
    .addCase(getEventPatientsThunk.pending, getPatientsThunkPending)
    .addCase(getEventPatientsThunk.fulfilled, getEventPatientsThunkFulfilled)
    .addCase(getEventPatientsThunk.rejected, getPatientsThunkRejected)
    .addCase(importPatientThunk.pending, importPatientThunkPending)
    .addCase(importPatientThunk.fulfilled, importPatientThunkFulfilled)
    .addCase(importPatientThunk.rejected, importPatientThunkRejected)
    .addCase(createModifyPatientThunk.pending, createModifyPatientThunkPending)
    .addCase(createModifyPatientThunk.fulfilled, createModifyPatientThunkFulfilled)
    .addCase(createModifyPatientThunk.rejected, createModifyPatientThunkRejected)
    .addCase(deletePatientThunk.pending, deletePatientThunkPending)
    .addCase(deletePatientThunk.fulfilled, deletePatientThunkFulfilled)
    .addCase(deletePatientThunk.rejected, deletePatientThunkRejected)
    .addCase(switchPatientShareThunk.fulfilled, switchPatientShareThunkFulfilled);
};
