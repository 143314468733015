import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import { Button, Input } from '../../components/UI';
import { resetSettings } from '../../redux/slices/settingsSlice';
import { getDictionariesThunk } from '../../redux/thunks/dictionarySettingsThunk';
import {
  getImagesThunk, updateEventLinkThunk,
} from '../../redux/thunks/settingsThunk';
import DictionariesModal from './components/DictionariesModal/DictionariesModal';
import ImagesModal from './components/ImagesModal/ImagesModal';
import s from './Settings.module.scss';

const Settings = () => {
  const dispatch = useDispatch();
  const sheetUrl = useSelector(state => state.userInfo.eventConfigs.sheet);
  const isConfigsLoading = useSelector(state => state.settings.isConfigsLoading);
  const [ isImageModalActive, setIsImageModalActive ] = useState(false);
  const [ isDictionaryModalActive, setIsDictionaryModalActive ] = useState(false);
  const [ fileUrl, setFileUrl ] = useState(sheetUrl || '');

  useEffect(() => {
    dispatch(getImagesThunk());
    dispatch(getDictionariesThunk());
    return () => dispatch(resetSettings());
  }, []);

  const onLinkClick = () => {
    dispatch(updateEventLinkThunk({ sheet: fileUrl }));
  };

  return (
    <div className={s.settings_container}>
      <Heading />
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className="cards">
              <div className={s.settings}>
                <div className={s.settingItem}>
                  <div className={s.settingLable}>Event Input File:</div>
                  <div className={s.settingInput}>
                    <Input value={fileUrl} onChange={e => setFileUrl(e.target.value)} />
                  </div>
                  <div>
                    <Button
                      variant='light'
                      loading={isConfigsLoading}
                      onClick={onLinkClick}
                      disabled={!fileUrl}
                      className={s.linkButton}>
                      {fileUrl && fileUrl === sheetUrl ? 'Update' : 'Link'}
                    </Button>
                  </div>
                </div>
                <div className={s.tabs}>
                  <div>
                    <Button
                      variant='light'
                      onClick={() => setIsImageModalActive(true)}
                      className={s.imagesButton}>Images</Button>
                  </div>
                  <div>
                    <Button
                      variant='light'
                      onClick={() => setIsDictionaryModalActive(true)}
                      className={s.languagesButton}>UI Languages</Button>
                  </div>
                </div>
              </div>
              {/* Modal */}
              {isImageModalActive
              && <ImagesModal
                closeModal={() => setIsImageModalActive(false)} />}
              {isDictionaryModalActive
              && <DictionariesModal
                closeModal={() => setIsDictionaryModalActive(false)} />}
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Settings;
