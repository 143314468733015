import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_TYPES } from '../../constants/names';

import { resetAlert } from '../../redux/slices/alertSlice';
import s from './Alert.module.scss';

const { WARNING, SUCCESS, ERROR } = ALERT_TYPES;

const alertTypesClassnames = {
  [ERROR]: 'alertError',
  [WARNING]: 'alertWarning',
  [SUCCESS]: 'alertSuccess',
};

const Alert = () => {
  const dispatch = useDispatch();
  const {
    message, isActive, type, timer,
  } = useSelector(state => state.alertData);

  useEffect(() => {
    let timeoutId;
    if (isActive) {
      timeoutId = setTimeout(() => {
        dispatch(resetAlert());
      }, timer);
    }
    return () => clearTimeout(timeoutId);
  }, [ isActive, message ]);

  return (
    <div className={classNames(s.alert, s[alertTypesClassnames[type]], { [s.active]: isActive })}>
      <div>
        {message}
      </div>
    </div>
  );
};

export default Alert;
