import classNames from 'classnames';
import s from '../PatientCasesModal.module.scss';

const PatientImagesSection = ({
  activeFiles, filesHandler, onRecycleClick, setShowPhoto, isView,
}) => (
  <div>
    <div className={classNames(s.section, s.lastSection)}>
      <div className={s.row}>
        <div>Image File(s):</div>
        <div>
          {activeFiles?.length
            ? activeFiles.map((item, i) => {
              const imageUrl = (!item.new ? process.env.REACT_APP_IMAGE_URL : '') + item.mozJpeg;
              return (
                <div key={i} className={s.patientRowWrapper}>
                  <div className={s.imageCard}>
                    <div className={s.imageCardName}>{item.name}</div>
                    <div className={s.imageCardImage}>
                      <img src={imageUrl} alt='' />
                    </div>
                    <div
                      onClick={() => setShowPhoto(imageUrl)}
                      className={s.imageCardView}>View</div>
                  </div>
                  {/* <Row className={s.patientRow}> */}
                  {/* {item.name} */}
                  {/* </Row> */}
                  {!isView && <div
                    onClick={() => onRecycleClick(item.name)}
                    className={s.recycleIcon} />}
                </div>
              );
            })
            : <div>
              {!isView && <div className={s.dropzone}>
                <label className={classNames(s.chooseButton, s.addImageButton, s.grayButton)} htmlFor='chooseFiles'>
                  Add Image File(s) +
                </label>
                <input
                  multiple
                  onChange={filesHandler}
                  type='file'
                  id='chooseFiles'
                  className={s.fileInput}
                  accept=".jpg,.jpeg,.png" />
              </div>}
            </div>
            }

        </div>
      </div>
      {!!activeFiles?.length && <div className={s.row}>
        <div />
        <div>
          {!isView && <div className={s.dropzone}>
            <label className={classNames(s.chooseButton, s.addImageButton, s.grayButton)} htmlFor='chooseFilesOuter'>
              Add Image File(s) +
            </label>
            <input
              multiple
              onChange={filesHandler}
              type='file'
              id='chooseFilesOuter'
              className={s.fileInput}
              accept=".jpg,.jpeg,.png,.jfif" />
          </div>}
        </div>
        </div>}
    </div>
  </div>
);

export default PatientImagesSection;
