export const USER_ROLES_IDS = {
  EC_ADMIN: 1,
  EVENT_MODERATOR: 2,
  SUPER_USER: 4,
  ANALYSIS_ADMIN: 5,
};

export const EVENT_TYPE_IDS = {
  VIRTUAL: 1,
  FACE: 2,
  BOTH: 3,
};
